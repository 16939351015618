import { useState } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import Layout from '../../components/Layout';
import HeadTags from '../../components/HeadTags';
import DevicePreview from '../../components/DevicePreview';
import CreateLinkModal from './components/CreateLinkModal';
import ShareLinkMenu from './components/ShareLinkMenu';
import EditLinkModal from './components/EditLinkModal';
import DeleteLinkModal from './components/DeleteLinkModal';
import LinkList from './components/LinkList';
import SocialList from './components/SocialList';
import CreateSocialModal from './components/CreateSocialModal';
import useAppContext from '../../hooks/useAppContext';
import { getLinks, getSocials, removeLink } from '../../services/profile.service';
import QueryKeys from '../../utils/queryKeys';

const Links = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isSocialModalOpen, setIsSocialModalOpen] = useState(false);
  const [editingSocialItem, setEditingSocialItem] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [removingItem, setRemovingItem] = useState(null);
  const [shareLinkMenuAnchorEl, setShareLinkMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const {
    profileState: { profile },
    createProfileModalState: { setIsCreateProfileModalOpen },
  } = useAppContext();

  const { status, error, data } = useQuery({
    queryKey: [QueryKeys.Links, profile?.id],
    queryFn: () => getLinks(profile?.id),
  });
  const socialsState = useQuery({
    queryKey: [QueryKeys.Socials, profile?.id],
    queryFn: () => getSocials(profile?.id),
  });

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => removeLink(profile.id, removingItem.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Links, profile?.id] });
      setRemovingItem(null);
      enqueueSnackbar('Remove link successfully', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onRemove = async () => {
    if (!removingItem) return;
    try {
      await mutation.mutateAsync();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Layout>
      <HeadTags title="Links & Socials" description="Manage your brand's links & socials" />
      <ShareLinkMenu anchorEl={shareLinkMenuAnchorEl} handleClose={() => setShareLinkMenuAnchorEl(null)} />
      <CreateLinkModal open={isLinkModalOpen} onClose={() => setIsLinkModalOpen(false)} />
      <EditLinkModal editingItem={editingItem} onClose={() => setEditingItem(null)} />
      <DeleteLinkModal removingItem={removingItem} onClose={() => setRemovingItem(null)} onRemove={onRemove} />
      <CreateSocialModal
        initData={editingSocialItem}
        open={isSocialModalOpen}
        onClose={() => {
          setIsSocialModalOpen(false);
          setEditingSocialItem(null);
        }}
      />
      {profile ? (
        <Grid container height="100%" spacing={2}>
          <Grid item xs={12} md={7}>
            <Box height="100%" width="100%" display="flex" flexDirection="column" gap={2} py={4} px={{ xs: 4, lg: 8 }}>
              <Box width="100%" display="flex" flexDirection="column" gap={1}>
                <Typography fontSize={20}>My Link</Typography>
                <Button
                  id="share-link-button"
                  fullWidth
                  variant="contained"
                  sx={{ px: 6 }}
                  onClick={(event) => setShareLinkMenuAnchorEl(event.currentTarget)}
                  startIcon={<IosShareIcon />}
                  color="secondary">
                  Share Link
                </Button>
              </Box>
              <Box width="100%" display="flex" flexDirection="column" gap={1}>
                <Typography fontSize={20}>Section 1: Featured</Typography>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ px: 6 }}
                  onClick={() => setIsLinkModalOpen(true)}
                  startIcon={<AddIcon />}
                  color="primary">
                  Add New Link
                </Button>
              </Box>
              <LinkList
                status={status}
                error={error}
                data={data}
                onClickEdit={(item) => setEditingItem(item)}
                onClickRemove={(item) => setRemovingItem(item)}
              />
              <Typography fontSize={20}>Section 2: My Socials</Typography>
              <SocialList
                status={socialsState.status}
                error={socialsState.error}
                data={socialsState.data}
                setEditingItem={(item) => setEditingSocialItem(item)}
                setIsSocialModalOpen={setIsSocialModalOpen}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <DevicePreview links={data?.data || []} socials={socialsState.data?.data || []} />
          </Grid>
        </Grid>
      ) : (
        <Box py={4} px={{ xs: 4, md: 8, lg: 12 }} display="flex" flexDirection="column" gap={3}>
          <Typography>You got nothing to display because you do not have any profile.</Typography>
          <Typography>
            Create a new profile{' '}
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.colors.activeLink }}
              onClick={() => setIsCreateProfileModalOpen(true)}>
              here
            </span>
            .
          </Typography>
        </Box>
      )}
    </Layout>
  );
};

export default Links;
