import { Dialog, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { updatePlan } from '../../../services/user.service';
import QueryKeys from '../../../utils/queryKeys';

const PremiumThemeModal = ({ open, onClose, onUpgradeCallback }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const planMutation = useMutation({
    mutationFn: ({ id }) => updatePlan({ planId: id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Update plan successfully', {
        variant: 'success',
      });
      onClose();
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onUpgrade = async () => {
    try {
      await planMutation.mutateAsync({ id: 'premium' });
      await onUpgradeCallback();
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        position="relative"
        alignItems="center"
      >
        <Box
          position="absolute"
          right="8px"
          top="8px"
          sx={{ cursor: 'pointer' }}
          onClick={onClose}
        >
          <CloseIcon />
        </Box>
        <Typography fontSize="20px" fontWeight={600}>
          Get more access?
        </Typography>
        <Typography>You need Premium plan to use this theme.</Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            p: 0.5,
            textTransform: 'none',
          }}
          onClick={onUpgrade}
        >
          Upgrade
        </Button>
      </Box>
    </Dialog>
  );
};

export default PremiumThemeModal;
