import { createContext } from 'react';

import useProfile from '../hooks/useProfile';
import useCreateProfileModal from '../hooks/useCreateProfileModal';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const profileState = useProfile();
  const createProfileModalState = useCreateProfileModal();

  return <AppContext.Provider value={{ profileState, createProfileModalState }}>{children}</AppContext.Provider>;
};
