import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, Grid, Stack, Skeleton, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import ThemeItem from './ThemeItem';
import PremiumThemeModal from './PremiumThemeModal';
import useAppContext from '../../../hooks/useAppContext';
import { updateTheme } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';

export default function ThemeSelection({ data, status, error, selectedTheme, setSelectedTheme }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { user, profile },
  } = useAppContext();
  const [isOpenPremiumThemeModal, setIsOpenPremiumThemeModal] = useState(false);

  const themes = useMemo(() => data?.data || [], [data?.data]);

  useEffect(() => {
    if (profile?.themeId) {
      const theme = themes.find(({ id }) => id === profile.themeId);
      setSelectedTheme(theme);
    }
  }, [profile?.themeId, themes, setSelectedTheme]);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data) => updateTheme(profile.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Updated theme successfully', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onSubmit = async () => {
    if (!selectedTheme) return;
    try {
      if (selectedTheme.planId === 'premium' && user?.planId !== 'premium') {
        setIsOpenPremiumThemeModal(true);
        return;
      }
      await onConfirmTheme();
    } catch (err) {
      console.error(err);
    }
  };

  const onConfirmTheme = async () => {
    try {
      await mutation.mutateAsync({ themeId: selectedTheme.id });
    } catch (err) {
      console.error(err);
    }
  };

  if (status === 'loading')
    return (
      <Box width="100%">
        <Stack spacing={2} width="100%">
          <Skeleton variant="rounded" width="25%" sx={{ aspectRatio: '1/1.5' }} />
          <Skeleton variant="rounded" width="25%" sx={{ aspectRatio: '1/1.5' }} />
        </Stack>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <PremiumThemeModal
        open={isOpenPremiumThemeModal}
        onClose={() => setIsOpenPremiumThemeModal(false)}
        onUpgradeCallback={onConfirmTheme}
      />
      <Typography fontWeight="500" fontSize="20px">
        Theme Selection: {status === 'error' && JSON.stringify(error)}
      </Typography>
      <Card
        sx={{
          p: 2,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
        <Grid container spacing={1}>
          {status === 'loading' ? (
            <>
              <Grid item xs={6} md={3}>
                <Skeleton variant="rounded" width="100%" sx={{ aspectRatio: '1/1.5' }} />
              </Grid>
              <Grid item xs={6} md={3}>
                <Skeleton variant="rounded" width="100%" sx={{ aspectRatio: '1/1.5' }} />
              </Grid>
              <Grid item xs={6} md={3}>
                <Skeleton variant="rounded" width="100%" sx={{ aspectRatio: '1/1.5' }} />
              </Grid>
              <Grid item xs={6} md={3}>
                <Skeleton variant="rounded" width="100%" sx={{ aspectRatio: '1/1.5' }} />
              </Grid>
            </>
          ) : (
            themes.map((theme) => (
              <ThemeItem
                key={theme.id}
                theme={theme}
                isSelected={theme.id === selectedTheme?.id}
                setSelectedTheme={setSelectedTheme}
              />
            ))
          )}
        </Grid>
      </Card>
      <Box>
        <Button
          variant="contained"
          color="neutral"
          onClick={onSubmit}
          disabled={profile?.themeId === selectedTheme?.id}>
          Save Theme
        </Button>
      </Box>
    </Box>
  );
}
