import { useEffect, useState } from 'react';
import { Menu } from '@mui/material';

import ShareLinkDefaultMenu from './ShareLinkDefaultMenu';
import ShareLinkSocialsMenu from './ShareLinkSocialsMenu';
import ShareLinkQRMenu from './ShareLinkQRMenu';

const pages = {
  default: ShareLinkDefaultMenu,
  socials: ShareLinkSocialsMenu,
  qr: ShareLinkQRMenu,
};
const ShareLinkMenu = ({ anchorEl, handleClose }) => {
  const [currentPage, setCurrentPage] = useState('default');

  const CurrentPage = pages[currentPage];

  useEffect(() => {
    if (!anchorEl) setCurrentPage('default');
  }, [anchorEl]);

  return (
    <Menu
      id="share-link-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'share-link-button' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'center' }}
    >
      <CurrentPage handleClose={handleClose} setCurrentPage={setCurrentPage} />
    </Menu>
  );
};

export default ShareLinkMenu;
