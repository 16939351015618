import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Box, Button, Typography, useTheme, IconButton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

import useAppContext from '../hooks/useAppContext';
import { addProfile } from '../services/profile.service';
import QueryKeys from '../utils/queryKeys';

const CreateProfileModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const {
    profileState: { user, setProfileIndex },
    createProfileModalState: { isCreateProfileModalOpen, setIsCreateProfileModalOpen },
  } = useAppContext();
  const navigate = useNavigate();

  const onClose = () => setIsCreateProfileModalOpen(false);

  const onCreateProfile = async () => {
    setIsLoading(true);
    try {
      await addProfile();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      setProfileIndex(user.profiles.length);
      enqueueSnackbar('Created new profile!', { variant: 'success' });
      onClose();
      navigate('/me');
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      open={isCreateProfileModalOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      PaperProps={{ sx: { overflowY: 'visible' } }}>
      <Box
        p={2}
        borderRadius={0.5}
        border="1px solid black"
        bgcolor={theme.colors.bgcolor}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2.5}
        position="relative">
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>
          <Typography fontSize="20px" fontWeight={500} textAlign="center">
            Create New Profile
          </Typography>
          <Typography textAlign="center" fontWeight={500}>
            Are you sure you want to create new account?
            <br />
            With the free plan you can only create two profiles
          </Typography>
        </Box>
        <Box display="flex" gap={5}>
          <Button variant="outlined" color="neutral" size="small" onClick={onClose}>
            Go back
          </Button>
          <Button variant="outlined" color="neutral" size="small" onClick={onCreateProfile} disabled={isLoading}>
            Create
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateProfileModal;
