import { useEffect, useState } from 'react';
import { Box, Typography, Grid, Chip } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { grey } from '@mui/material/colors';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import useAppContext from '../../../hooks/useAppContext';
import { updateProfileOrders } from '../../../services/user.service';
import QueryKeys from '../../../utils/queryKeys';

const ProfilesOrder = () => {
  const {
    profileState: { user },
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState(user?.profiles || []);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ ids }) => updateProfileOrders({ ids }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Updated profiles display order', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  useEffect(() => {
    setItems(user?.profiles || []);
  }, [user?.profiles]);

  const onDragEnd = async (result) => {
    try {
      if (!result.destination) return;

      const clonedItems = Array.from(items);
      const [reorderedItem] = clonedItems.splice(result.source.index, 1);
      clonedItems.splice(result.destination.index, 0, reorderedItem);

      setItems(clonedItems);
      const ids = clonedItems.map((item) => item.id);
      await mutation.mutateAsync({ ids });
    } catch (err) {
      console.error(err);
    }
  };
  if (!user) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography fontSize="20px" fontWeight={500}>
          Profiles Order
        </Typography>
        <Typography>Drag and drop to change the display order of your profiles</Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              display="flex"
              flexDirection="column"
              gap={1}
              width="100%">
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      bgcolor="white"
                      borderRadius={2}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Grid container>
                        <Grid item xs={1}>
                          <Box
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            py={1}
                            sx={{ borderRight: `1px solid ${grey[200]}` }}>
                            <SwapVertIcon sx={{ color: grey[800], fontSize: '32px' }} />
                          </Box>
                        </Grid>
                        <Grid item xs={11} sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
                          <img
                            src={item.avatar || '/images/default-avatar.webp'}
                            width={40}
                            height={40}
                            style={{ borderRadius: 20 }}
                            alt="avatar"
                          />
                          <Box flex={1} p={1}>
                            <Typography fontWeight={600}>{item.name}</Typography>
                            <Typography fontSize={12}>@{item.handle}</Typography>
                          </Box>
                          <Box>{index === 0 && <Chip label="Default" variant="outlined" color="primary" />}</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default ProfilesOrder;
