import { useEffect } from 'react';
import { Box, Button, Card, Typography, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import useAppContext from '../../../hooks/useAppContext';
import { updateBio } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';

export default function ProfileBio({ bio, setBio }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile },
  } = useAppContext();

  useEffect(() => {
    profile?.bio && setBio(profile?.bio);
  }, [profile?.bio, setBio]);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data) => updateBio(profile.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Updated bio successfully', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onSubmit = async () => {
    try {
      await mutation.mutateAsync({ bio });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontWeight="500" fontSize="20px">
        Profile Bio
      </Typography>
      <Card
        sx={{
          p: 2,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
        <Typography fontWeight="500" color="#6c6c6c">
          Bio
        </Typography>
        <TextField
          value={bio}
          onChange={(e) => setBio(e.target.value.slice(0, 140))}
          fullWidth
          size="small"
          variant="outlined"
          multiline
          rows={3}
        />
        <Typography fontWeight="500" fontSize="8px" color="#6C6C6C">
          (Max 140 characters)
        </Typography>
      </Card>
      <Box>
        <Button variant="contained" color="neutral" onClick={onSubmit}>
          Save Details
        </Button>
      </Box>
    </Box>
  );
}
