import { Box, Button, Container, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import HeadTags from '../../components/HeadTags';

const PrivacyPolicy = () => {
  return (
    <Box
      bgcolor="#f9adc2"
      py={10}
      sx={{
        backgroundImage: 'url(/images/landing-1-bg.webp)',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        '& *:not(h1)': { color: 'white', textAlign: 'justify' },
      }}>
      <HeadTags
        title="Privacy Policy"
        description="1. Information We Collect: 1.1. When you use Connexions, we may collect information that you provide to us, such as your name, email
          address, and other personal information. 1.2. We may collect information about your usage of the Service, including log data, device information, and
          cookies."
      />
      <Container sx={{ display: 'flex', flexDirection: 'column', gap: 1, position: 'relative' }}>
        <Button
          sx={{ position: 'absolute', top: -40, left: 0, '&:hover': { textDecoration: 'underline' } }}
          startIcon={<NavigateBeforeIcon />}
          component="a"
          href="/">
          Back to home
        </Button>
        <Typography textAlign="center" fontSize={40} fontWeight="bold" component="h1">
          Privacy Policy
        </Typography>
        <Typography fontStyle="italic">Last Updated: 18th Oct 2023</Typography>
        <Title>1. Information We Collect&nbsp;</Title>
        <Typography>
          1.1. When you use Connexions, we may collect information that you provide to us, such as your name, email
          address, and other personal information.
        </Typography>
        <Typography>
          1.2. We may collect information about your usage of the Service, including log data, device information, and
          cookies.
        </Typography>
        <Title>2. How We Use Your Information&nbsp;</Title>
        <Typography>
          2.1. We use the information we collect to provide, maintain, and improve the Service, as well as to
          communicate with you, including sending you updates and promotional messages.
        </Typography>
        <Typography>
          2.2. We may use your information for analytics, research, and to comply with legal obligations.
        </Typography>
        <Title>3. Sharing of Information&nbsp;</Title>
        <Typography>
          We do not share your personal information with third parties except as described in this Privacy Policy.
        </Typography>
        <Title>4. Security&nbsp;</Title>
        <Typography>
          We take reasonable measures to protect your information from unauthorized access or disclosure.
        </Typography>
        <Title>5. Changes to Privacy Policy&nbsp;</Title>
        <Typography>
          We may update our Privacy Policy from time to time. The most current version will be posted on the Service.
        </Typography>
        <Title>6. Contact Information&nbsp;</Title>
        <Typography>If you have any questions about this Privacy Policy, please contact us at g@mirl.club.</Typography>
      </Container>
    </Box>
  );
};

const Title = (props) => <Typography fontSize={24} fontWeight="bold" component="h2" sx={{ mt: 2 }} {...props} />;

export default PrivacyPolicy;
