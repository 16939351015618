import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import AuthLayout from '../../components/AuthLayout';
import HeadTags from '../../components/HeadTags';
import EmailPassword from './components/EmailPassword';
import Names from './components/Names';
import { register } from '../../services/auth.service';

const Register = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  const submit = async ({ username, name }) => {
    setLoading(true);
    try {
      const registerData = {
        ...data,
        username,
        name,
      };
      const res = await register(registerData);
      navigate(`/register/success?uid=${res.data?.userId}?`);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setLoading(false);
  };

  const render = () => {
    const { email, password } = data;
    if (email && password) return <Names submit={submit} loading={loading} />;
    return <EmailPassword onNext={setData} />;
  };

  return (
    <AuthLayout>
      <HeadTags title="Register" description="Sign up for a Connexions account" />
      {render()}
    </AuthLayout>
  );
};

export default Register;
