import { Box, Button, Container, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import parse from 'html-react-parser';

import useAuth from '../../../hooks/useAuth';

const About = () => {
  const { uid } = useAuth();

  return (
    <Box id="about" bgcolor="#f3f3f3">
      <Container
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: { xs: 8, lg: 10 },
          py: 6,
          px: 2,
        }}>
        <Box display="flex" flexDirection="column" justifyContent="center" gap={2} px={{ xs: 3, md: 5 }}>
          <Typography
            textAlign="center"
            fontFamily="TS Block, sans-serif"
            fontSize={{ xs: '32px', md: '48px' }}
            variant="h2">
            Your plan. <br /> Your choice.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-around"
          alignItems={{ xs: 'center', md: 'unset' }}
          gap={{ xs: 2, lg: 4 }}>
          {plans.map((plan) => (
            <Box
              flex={1}
              key={plan.title}
              display="flex"
              flexDirection="column"
              gap={3}
              borderRadius={4}
              py={3}
              px={3}
              border="2px solid black"
              bgcolor={plan.bgcolor}
              position="relative"
              width={{ xs: '80%', sm: '60%', md: 'unset' }}
              sx={{ '& .MuiTypography-root': { fontFamily: 'Lexend, sans-serif' } }}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography fontWeight="bold" textTransform="uppercase" fontSize={{ xs: 18, lg: 20 }}>
                  {plan.title}
                </Typography>
                <Typography fontWeight={500} fontSize={14}>
                  {plan.subtitle}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <PlanFeatureItem content={plan.themes} />
                <PlanFeatureItem content={plan.profileCount} />
                <PlanFeatureItem title="Featured NFTs" content={plan.featuringNFTArtworks} />
                <PlanFeatureItem
                  title="Clicks and Views"
                  content={plan.clicksAndViews}
                  height={{ xs: 'unset', md: '4.5rem' }}
                />
                {plan.customBackground && <PlanFeatureItem content="Customizable profile backgrounds" />}
                {plan.saveContact && <PlanFeatureItem content="Visitors save your contact with a click" />}
              </Box>
              <Button
                variant="contained"
                color="neutral"
                sx={{ borderRadius: 20, mt: 'auto' }}
                component="a"
                href={uid ? '/links' : '/login'}
                disabled={plan.isComingSoon}>
                {plan.isComingSoon
                  ? 'Coming soon'
                  : 'Get for ' +
                    (plan.price ? `USD ${plan.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}` : 'free')}
              </Button>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

const PlanFeatureItem = ({ title, content, height }) => (
  <Box display="flex" gap={1} height={height}>
    <CheckCircleIcon sx={{ fontSize: 20, mt: 0.5 }} />
    <Typography>
      {title && <strong>{title}: </strong>}
      {parse(content)}{' '}
    </Typography>
  </Box>
);

const plans = [
  {
    title: 'Free',
    bgcolor: '#e5ebf9',
    subtitle: 'Free plan for all users.',
    price: 0,
    themes: 'Customisable themes, buttons and fonts.',
    clicksAndViews: 'Past 90 days.',
    profileCount: '1 Profile',
    featuringNFTArtworks: '21 thumbnails',
    customBackground: false,
    saveContact: false,
  },
  {
    title: 'Pro',
    bgcolor: '#d695ff',
    subtitle: 'Ideal for professional individuals.',
    price: 50,
    themes: 'Enhanced customisable themes, buttons and fonts.',
    clicksAndViews: 'Lifetime analytics and powerful insights.',
    profileCount: 'Up to 4 Profiles',
    featuringNFTArtworks: '60 thumbnails',
    customBackground: true,
    saveContact: true,
    isComingSoon: true,
  },
  {
    title: 'Premium',
    bgcolor: '#98e4ff',
    subtitle: 'More customisation.',
    price: 100,
    themes: 'Enhanced customisable themes, buttons and fonts.',
    clicksAndViews: 'Lifetime analytics and powerful insights. <br/> Allow data export.',
    profileCount: 'Up to 10 Profiles',
    featuringNFTArtworks: 'No restrictions',
    customisedTheme: 'Only for corporate companies',
    customBackground: true,
    saveContact: true,
    isComingSoon: true,
  },
];

export default About;
