import { Box, Grid, Typography, useTheme } from '@mui/material';

import Header from './Header';
import Sidebar from './Sidebar';
import MobileBottomNav from './MobileBottomNav';

const Layout = ({ children }) => {
  const theme = useTheme();

  return (
    <Box id="main-app-layout" height="100vh" bgcolor={theme.colors.bgcolor}>
      <Header />
      <Box height={{ xs: 'calc(100vh - 155px)', md: 'calc(100vh - 85px)' }} display="flex" flexDirection="column">
        <Grid container height="100%">
          <Grid item xs={2} height="100%" sx={{ display: { xs: 'none', md: 'unset' } }}>
            <Sidebar />
          </Grid>
          <Grid item xs={12} md={10} height="100%" sx={{ overflow: 'auto' }}>
            <Box minHeight="100%" bgcolor={theme.colors.contentBgColor} pb={{ xs: '5%', md: '3%' }}>
              {children}
              <Typography textAlign="center" fontWeight="bold" fontFamily="Himagsikan !important">
                Connexions - powered by Made In Real Life
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <MobileBottomNav />
    </Box>
  );
};

export default Layout;
