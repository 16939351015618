import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, alpha } from '@mui/material';

import { navigations } from './Sidebar';

const MobileBottomNav = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = (path) => path === pathname;

  return (
    <Box display={{ xs: 'flex', md: 'none' }} py={1} px={2} justifyContent="space-around" gap={1}>
      {navigations.map((item) => (
        <Box
          flex={1}
          key={item.text}
          display="flex"
          flexDirection="column"
          alignItems="center"
          p={0.5}
          gap={0.5}
          borderRadius={1}
          bgcolor={isActive(item.path) ? alpha(theme.colors.activeLink, 0.25) : 'transparent'}
          sx={{
            transition: 'all ease 0.3s',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: alpha(theme.colors.activeLink, isActive(item.path) ? 0.25 : 0.05),
            },
          }}
          onClick={() => navigate(item.path)}>
          <item.icon />
          <Typography fontSize={12} fontWeight={500} fontFamily="Lexend">
            {item.shortText ?? item.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MobileBottomNav;
