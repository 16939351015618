import { Box, Container, Typography } from '@mui/material';

const About = () => {
  return (
    <Box id="about" bgcolor="#f3f3f3">
      <Container
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: { xs: 8, md: 12, lg: 15 },
          py: 8,
          px: 2,
        }}>
        <Box display="flex" flexDirection="column" justifyContent="center" gap={2} px={{ xs: 3, md: 5 }}>
          <Typography
            textAlign="center"
            fontFamily="TS Block, sans-serif"
            color="#57a7c4"
            fontSize={{ xs: '32px', md: '48px' }}
            variant="h2">
            One Platform. One Link. <span style={{ color: '#ed6d60', fontFamily: 'inherit' }}>Multiple Brands.</span>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-around"
          alignItems={{ xs: 'center', md: 'flex-start' }}
          gap={{ xs: 2, lg: 4 }}>
          {features.map(({ imageURL, title, description, imageMr }) => (
            <Box
              flex={1}
              key={title}
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              borderRadius={5}
              py={2}
              px={1}
              border="3px solid #57a7c4"
              width={{ xs: '80%', sm: '60%', md: 'unset' }}
              position="relative"
              sx={{ '&:hover .content': { opacity: 1 } }}>
              <Box height="4rem" sx={{ fontSize: { xs: 18, lg: 20 } }} display="flex" alignItems="center">
                <Typography
                  fontFamily="Lexend, sans-serif"
                  textAlign="center"
                  fontWeight="bold"
                  textTransform="uppercase"
                  fontSize={{ xs: 18, lg: 20 }}>
                  {title}
                </Typography>
              </Box>
              <img src={imageURL} alt="" height={120} style={{ objectFit: 'contain', marginRight: imageMr ?? 0 }} />
              <Box
                className="content"
                flex={1}
                position="absolute"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
                borderRadius={4}
                py={2}
                px={1}
                sx={{ top: 0, bottom: 0, opacity: 0, transition: 'all 0.3s ease' }}
                bgcolor="black">
                <Box height="4rem" sx={{ fontSize: { xs: 18, lg: 20 } }} display="flex" alignItems="center">
                  <Typography
                    color="#ed6d60"
                    fontFamily="Lexend, sans-serif"
                    textAlign="center"
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize={{ xs: 18, lg: 20 }}>
                    {title}
                  </Typography>
                </Box>
                <Typography color="white" fontFamily="Lexend, sans-serif" textAlign="center" fontSize={16}>
                  {description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

const features = [
  {
    imageURL: '/images/icons/consolidate-brand.png',
    title: 'Consolidate Your Brands',
    description:
      'Seamlessly switch between your brands from a single digital hub, making it easier than ever for your audience to discover the full scope of your capabilities.',
  },
  {
    imageURL: '/images/icons/layout-custom.png',
    imageMr: -20,
    title: 'Intuitive Brand Management',
    description:
      'Tailor each brand with its distinct bio, links, and visual identity. Effortlessly update and edit brand information in real-time!',
  },
  {
    imageURL: '/images/icons/tools.png',
    title: 'Analytical Insights',
    description:
      'We incorporate CRM functionalities, empowering creators to email subscribers and effectively connect with their audience.',
  },
];

export default About;
