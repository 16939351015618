import { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import HeaderMenu from './HeaderMenu';

export const navigations = [
  { text: 'Home', url: '#' },
  { text: 'About', url: '#about' },
  { text: 'Features', url: '#features' },
  { text: 'FAQs', url: '#faqs' },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container sx={{ p: 2 }}>
      <HeaderMenu isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={6}>
        <Box alignItems="center" gap={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
          {navigations.map(({ text, url }) => (
            <a key={text} href={url} style={{ textDecoration: 'none' }}>
              <Typography
                color="white"
                sx={{ transition: 'all 0.3 ease', '&:hover': { opacity: 0.8 } }}
                fontFamily="Lexend, sans-serif"
                fontSize={{ xs: 16, lg: 20, xl: 25 }}>
                {text}
              </Typography>
            </a>
          ))}
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <MenuIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={() => setIsOpen(true)} />
        </Box>
      </Box>
    </Container>
  );
};

export default Header;
