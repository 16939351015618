import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getProfile } from '../services/profile.service';
import QueryKeys from '../utils/queryKeys';

const useProfile = () => {
  const [profileIndex, setProfileIndex] = useState(0);
  const [profile, setProfile] = useState(null);
  const { data } = useQuery({
    queryKey: [QueryKeys.Profile],
    queryFn: getProfile,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 0,
  });

  useEffect(() => {
    if (data?.data?.profiles) {
      setProfile(data.data.profiles[profileIndex]);
    } else setProfile(null);
  }, [data?.data.profiles, profileIndex]);

  return { user: data?.data || {}, profile, profileIndex, setProfileIndex };
};

export default useProfile;
