import { useQuery } from '@tanstack/react-query';

import { getUserProfile } from '../services/profile.service';
import QueryKeys from '../utils/queryKeys';

const useUserProfile = ({ username }) => {
  const { status, data } = useQuery({
    queryKey: [QueryKeys.UserProfile],
    queryFn: () => getUserProfile({ username }),
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 0,
  });

  return { status, data: data || { data: [] } };
};

export default useUserProfile;
