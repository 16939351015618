import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './index.css';
import App from './App';
import { AppContextProvider } from './contexts/app.context';

const theme = createTheme({
  palette: {
    primary: { main: '#d33379', contrastText: '#fff' },
    secondary: { main: '#868fc7', contrastText: '#fff' },
    landingBlue: { main: '#36baea', contrastText: '#fff' },
    neutral: { main: '#000', contrastText: '#fff' },
  },
  colors: {
    bgcolor: '#e5ebf9',
    contentBgColor: '#f1f2f8',
    activeLink: '#d33379',
    main: '#868fc7',
  },
  typography: {
    fontFamily: "'Overpass', sans-serif;",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
        },
      },
    },
  },
});
// default: 10s for stale time, 10m for cache time
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 10 * 1000, cacheTime: 10 * 60 * 1000 },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <AppContextProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AppContextProvider>
      </SnackbarProvider>
    </BrowserRouter>
    {/* <ReactQueryDevtools /> */}
  </QueryClientProvider>
  // </React.StrictMode>
);
