import { Box, Typography, useTheme } from '@mui/material';

const TextInput = ({
  label,
  name,
  showError,
  error,
  type = 'text',
  value,
  onChange,
  onBlur = () => {},
  placeholder = '',
  bgcolor,
  color,
  StartIcon,
  EndIcon,
}) => {
  const theme = useTheme();

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={0.25}>
      {label && (
        <Box>
          <Typography fontWeight={500}>{label}</Typography>
        </Box>
      )}
      <Box
        px={2.25}
        py={1.5}
        bgcolor={bgcolor || theme.colors.main}
        borderRadius={2}
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          '& ::placeholder': {
            color: color || 'white',
            opacity: 0.6,
          },
        }}>
        {StartIcon || null}
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          style={{
            flex: 1,
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            color: color || 'white',
            fontWeight: 500,
          }}
        />
        {EndIcon}
      </Box>
      {showError && (
        <Box height="1.2rem">
          {error && (
            <Typography fontSize="10px" fontStyle="italic" color="error">
              *{error}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TextInput;
