import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Box, Typography, Button, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';

import { auth } from '../../configs/firebase.config';
import AuthLayout from '../../components/AuthLayout';
import HeadTags from '../../components/HeadTags';
import TextInput from '../../components/TextInput';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const verified = searchParams.get('verified');

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const submit = async () => {
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/links');
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <HeadTags title="Login" description="Sign in to your Connexions account" />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={{ xs: 4, md: 8 }}>
        <Box width={{ xs: '80vw', sm: '600px' }} display="flex" flexDirection="column" align="center" gap={1}>
          {verified ? (
            <>
              <Typography fontSize="28px" fontWeight={700} align="center">
                Account verified!
              </Typography>
              <Typography fontSize="20px" align="center">
                Your account has been created
              </Typography>
              <Typography fontSize="20px" align="center">
                Login to set up your Connexions account now!{' '}
              </Typography>
            </>
          ) : (
            <Typography fontSize="20px" fontWeight={500} align="center">
              Start personalizing your profile on your NFC Product by signing in to your Connexions Account.
            </Typography>
          )}
        </Box>
        <Box width={{ xs: '80vw', sm: '400px' }} display="flex" flexDirection="column" alignItems="center" gap={2}>
          <TextInput label="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <Box width="100%" display="flex" flexDirection="column" alignItems="flex-end" gap={1}>
            <TextInput
              label="Password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              EndIcon={
                showPassword ? (
                  <VisibilityOffIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={toggleShowPassword} />
                ) : (
                  <VisibilityIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={toggleShowPassword} />
                )
              }
            />
            <Typography
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => navigate('/forgot-password')}
              fontSize={14}>
              Forgot password?
            </Typography>
          </Box>

          <Button
            sx={{
              mt: 2,
              px: 6,
              borderRadius: 5,
              border: `2px solid ${theme.colors.main}`,
              color: 'black',
              bgcolor: 'white',
              textTransform: 'none',
              fontWeight: 500,
            }}
            onClick={submit}
            disabled={loading || !email.trim() || !password.trim()}>
            Let&apos;s Go
          </Button>
          {!verified && (
            <Typography align="center">
              Haven&apos;t got an account? Register{' '}
              <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => navigate('/register')}>
                here
              </span>
            </Typography>
          )}
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Login;
