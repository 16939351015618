import { useState, useEffect } from 'react';
import { Box, Grid, Stack, Skeleton, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import useAppContext from '../../../hooks/useAppContext';
import { updateLinkOrders } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';

const LinkList = ({ status, error, data, onClickEdit, onClickRemove }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState(data?.data || []);
  const {
    profileState: { profile },
  } = useAppContext();
  useEffect(() => {
    setItems(data?.data || []);
  }, [data?.data]);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ ids }) => updateLinkOrders(profile.id, { ids }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Links] });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onDragEnd = async (result) => {
    try {
      if (!result.destination) return;

      const clonedItems = Array.from(items);
      const [reorderedItem] = clonedItems.splice(result.source.index, 1);
      clonedItems.splice(result.destination.index, 0, reorderedItem);

      setItems(clonedItems);
      const ids = clonedItems.map((item) => item.id);
      await mutation.mutateAsync({ ids });
    } catch (err) {
      console.error(err);
    }
  };

  if (status === 'error')
    return (
      <Box width="100%">
        <Typography align="center" color="error">
          {JSON.stringify(error)}
        </Typography>
      </Box>
    );

  if (status === 'loading')
    return (
      <Box width="100%">
        <Stack spacing={2} width="100%">
          <Skeleton variant="rounded" width="100%" height={80} />
          <Skeleton variant="rounded" width="100%" height={80} />
        </Stack>
      </Box>
    );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            maxHeight={624}
            overflow="scroll"
            display="flex"
            flexDirection="column"
            gap={2}
            width="100%">
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <Box
                    bgcolor="white"
                    borderRadius={2}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Box
                          height="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            cursor: 'pointer',
                            borderRight: `1px solid ${grey[200]}`,
                          }}>
                          <SwapVertIcon sx={{ color: grey[800], fontSize: '32px' }} />
                        </Box>
                      </Grid>
                      <Grid item xs={11} sx={{ display: 'flex' }}>
                        <Box flex={1} p={1} maxWidth="calc(100% - 96px)">
                          <Stack spacing={0.5}>
                            <Typography fontWeight={500}>{item.title}</Typography>
                            <Typography
                              fontWeight={500}
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                              {item.url}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box p={2} display="flex" flexDirection="column" gap={1}>
                          <Button
                            variant="outlined"
                            color="neutral"
                            size="small"
                            sx={{
                              fontSize: '12px',
                              textTransform: 'none',
                              p: 0,
                            }}
                            onClick={() => onClickEdit(item)}>
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            sx={{
                              fontSize: '12px',
                              textTransform: 'none',
                              p: 0,
                            }}
                            onClick={() => onClickRemove(item)}>
                            Remove
                          </Button>
                          <Typography fontSize="12px" color={grey[700]}>
                            {item.totalClicks.toLocaleString()} clicks
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default LinkList;
