import { Dialog, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DeleteLinkModal = ({ removingItem, onClose, onRemove }) => {
  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={!!removingItem}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        position="relative"
      >
        <Box
          position="absolute"
          right="8px"
          top="8px"
          sx={{ cursor: 'pointer' }}
          onClick={onClose}
        >
          <CloseIcon />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            Do you want to remove your {removingItem?.title} link?
          </Typography>
          <Typography fontSize="12px" fontStyle="italic" color="error">
            Your action cannot be undone!
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ fontSize: '12px', textTransform: 'none', p: 0 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{ fontSize: '12px', textTransform: 'none', p: 0 }}
            onClick={onRemove}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteLinkModal;
