import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import validator from 'validator';

import AuthLayout from '../../components/AuthLayout';
import HeadTags from '../../components/HeadTags';
import TextInput from '../../components/TextInput';
import { requestResetPassword } from '../../services/auth.service';
import { checkExisted } from '../../services/user.service';

const initialValues = { email: '' };

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const theme = useTheme();
  const cachedEmails = useRef([]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Invalid email')
      .required('Email is required')
      .test('non-existent', 'Email not registered', async (value) => {
        if (!validator.isEmail(value.trim())) return true;
        const cached = cachedEmails.current.find((item) => item.email === value.trim());
        if (cached) return cached.valid;
        setLoading((prev) => true);
        let valid = true;
        try {
          await checkExisted({ email: value.trim() });
          cachedEmails.current.push({ email: value.trim(), valid: false });
          setLoading((prev) => false);
          console.log('hello not exists');
          valid = false;
        } catch (err) {
          cachedEmails.current.push({ email: value.trim(), valid: true });
          console.log('hello exists');
          valid = true;
        }
        setLoading((prev) => false);
        return valid;
      }),
  });

  const onSubmit = async ({ email }) => {
    setLoading(true);
    try {
      await requestResetPassword({ email });
      setIsSent(true);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setLoading(false);
  };

  const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  return (
    <AuthLayout>
      <HeadTags title="Forgot Password" description="Enter your email to reset your password" />
      {isSent ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          py={10}>
          <Box width={{ xs: '80vw', md: '800px' }} display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Typography fontSize="24px" fontWeight={500} align="center">
              Check Your Mail
            </Typography>
            <Typography fontWeight={500} align="center">
              We have send a link to your email to reset your password. <br /> If you have trouble with receiving the
              link, you can check your junk mail for the mail.
            </Typography>
            <Typography fontWeight={500}>
              Send another link{' '}
              <span
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: theme.colors.activeLink,
                }}
                onClick={onSubmit}>
                here
              </span>
            </Typography>
          </Box>
          <Button variant="link" onClick={() => navigate('/')} sx={{ alignSelf: 'flex-start', textTransform: 'none' }}>
            {'< Back to homepage'}
          </Button>
        </Box>
      ) : (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          py={4}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
              <Typography fontSize="24px" fontWeight={500} align="center">
                Reset Password
              </Typography>
              <Typography fontSize="20px" align="center">
                Enter your email address and we will send you the recovery link
              </Typography>
            </Box>
            <Box width={{ xs: '80vw', sm: '400px' }} display="flex" flexDirection="column" alignItems="center">
              <TextInput
                label="Email"
                placeholder="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                showError
                error={touched.email && errors.email}
              />
              <Button
                sx={{
                  mt: 2,
                  px: 6,
                  borderRadius: 5,
                  border: `2px solid ${theme.colors.main}`,
                  color: 'black',
                  bgcolor: 'white',
                  textTransform: 'none',
                  fontWeight: 500,
                }}
                disabled={!isValid || loading}
                onClick={handleSubmit}>
                Reset Password
              </Button>
            </Box>
          </Box>
          <Button
            variant="link"
            onClick={() => navigate('/login')}
            sx={{ alignSelf: 'flex-start', textTransform: 'none' }}>
            {'< Back to login'}
          </Button>
        </Box>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
