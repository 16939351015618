import { Box, Button, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { getIconSrc } from '../utils/constants';
import environments from '../utils/environments';

const { BACKEND_URL } = environments;

const MobileProfileView = ({
  isPreview,
  profileId,
  bio,
  profileLabel,
  name,
  handle,
  avatar,
  isVcardEnabled,
  theme,
  layoutId,
  links,
  socials,
  profiles,
  profileIndex,
  setProfileIndex,
  onClickLink,
  onClickSocial,
}) => {
  const { solidBgColor, coverImage, gradientColors, gradientTextColor, layoutSpecific } = theme;
  const layout = layoutId ?? 'dynamicWaves';
  const {
    headerTextColor,
    linkBgColor,
    linkIconColor,
    linkTextColor,
    socialIconsColor,
    footerTextColor,
    paginationDotsColor,
  } = layoutSpecific[layout];

  const SaveContactButton = () =>
    isVcardEnabled && (
      <Button
        variant="contained"
        color="neutral"
        size="small"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 3,
          fontFamily: 'Conthrax, sans-serif',
          fontSize: 10,
          textAlign: 'left',
          lineHeight: 1.25,
          textTransform: 'none',
          borderRadius: '0 0 0 20px',
          '& .MuiSvgIcon-root': { fontSize: 20 },
        }}
        startIcon={<PersonAddIcon />}
        {...(!isPreview && {
          component: 'a',
          href: `${BACKEND_URL}/api/v1/profiles/vcard/${profileId}`,
          target: '_blank',
          rel: 'noopener noreferrer',
        })}>
        Save <br /> Contact
      </Button>
    );
  const AvatarAndName = ({ textColor, containerProps }) => (
    <Box display="flex" gap={2} alignItems="center" zIndex={1} mb={isPreview ? 0.5 : 1.5} {...containerProps}>
      <Box width="23%" borderRadius="50%" overflow="hidden" sx={{ aspectRatio: '1/1' }}>
        <img
          src={avatar || '/images/default-avatar.webp'}
          alt="avatar"
          style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'center', display: 'block' }}
        />
      </Box>
      <Box>
        <Typography fontSize="20px" fontWeight={600} color={textColor} fontFamily="Lexend, sans-serif">
          {name}
        </Typography>
        <Typography fontSize="12px" fontWeight={600} color={textColor} fontFamily="Lexend, sans-serif">
          @{handle}
        </Typography>
      </Box>
    </Box>
  );
  const Bio = ({ width }) => (
    <Box
      display="flex"
      alignItems="center"
      height="5em"
      sx={{ fontSize: isPreview ? { xs: 9.5, md: 12 } : 12 }}
      mt={{ xs: 0, md: 1, xl: 2 }}>
      <Typography
        fontSize={isPreview ? { xs: 9.5, md: 12 } : 12}
        lineHeight="1.25"
        color={headerTextColor}
        sx={{ whiteSpace: 'pre-line', position: 'sticky', zIndex: 1, width }}
        fontFamily="Overpass, sans-serif">
        {bio}
      </Typography>
    </Box>
  );
  const Links = () => (
    <Box width="100%" height={isPreview ? { xs: 5 * 40, lg: 5 * 46 } : 5 * 46} overflow="scroll">
      <Box display="flex" flexDirection="column" alignItems="center" pr="3px" pb="3px" ml="3px" gap={1}>
        {links.map((item) => (
          <Box
            key={`${item.title}-${item.url}`}
            width="100%"
            px={2}
            py={0.5}
            bgcolor={linkBgColor}
            borderRadius={10}
            display="flex"
            alignItems="center"
            gap={1}
            boxShadow={`3px 3px 1px 0px ${linkTextColor}`}
            {...(!isPreview && {
              component: 'a',
              href: item.url,
              target: '_blank',
              rel: 'noopener noreferrer',
              onClick: () => onClickLink(item),
              sx: { textDecoration: 'none', transition: 'all 0.3s ease', '&:hover': { opacity: 0.8 } },
            })}>
            <Box width={isPreview ? { xs: '24px', lg: '30px' } : 30} sx={{ aspectRatio: '1/1' }}>
              <img
                src={item.icon || getIconSrc(`${linkIconColor}/${item.type}_Icon.png`)}
                alt="icon"
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  display: 'block',
                }}
              />
            </Box>
            <Typography
              fontSize={isPreview ? 12 : 14}
              fontWeight={600}
              color={linkTextColor}
              fontFamily="Conthrax, sans-serif">
              {item.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
  const Socials = () => (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
      <Typography
        fontFamily="Conthrax, sans-serif"
        fontSize={isPreview ? 12 : 16}
        textAlign="center"
        color={socialIconsColor}>
        MY SOCIALS
      </Typography>
      <Box display="flex" gap={isPreview ? { xs: 0, lg: 0.5 } : 0.5}>
        {socials.map((social) => (
          <Box
            key={social.url}
            width={isPreview ? { xs: 32, md: 40 } : 40}
            {...(!isPreview && {
              component: 'a',
              href: social.url,
              target: '_blank',
              rel: 'noopener noreferrer',
              onClick: () => onClickSocial(social),
            })}>
            <img
              src={getIconSrc(`${socialIconsColor}/${social.type}_Icon.png`)}
              width="100%"
              fill={socialIconsColor}
              alt={social.url}
              style={{ borderRadius: 12 }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
  const Pagination = () => (
    <Box display="flex" alignItems="center" gap={1} zIndex={3} mb={1.5}>
      <IconButton onClick={onPrev}>
        <ArrowBackIcon htmlColor={paginationDotsColor} />
      </IconButton>
      {profiles.map((_, index) => (
        <Box
          key={index}
          width={8}
          height={8}
          borderRadius="50%"
          bgcolor={paginationDotsColor}
          sx={{ opacity: index === profileIndex ? 1 : 0.5 }}
        />
      ))}
      <IconButton onClick={onNext}>
        <ArrowForwardIcon htmlColor={paginationDotsColor} />
      </IconButton>
    </Box>
  );
  const FooterText = () => (
    <Typography
      fontSize={{ md: '10px', lg: '12px' }}
      fontWeight={500}
      color={footerTextColor}
      fontFamily="Himagsikan, sans-serif"
      sx={{ zIndex: 3 }}>
      CONNEXIONS
    </Typography>
  );

  const onPrev = () => {
    if (isPreview) return;
    const newIndex = profileIndex === 0 ? profiles.length - 1 : profileIndex - 1;
    setProfileIndex?.(newIndex);
  };
  const onNext = () => {
    if (isPreview) return;
    const newIndex = profileIndex === profiles.length - 1 ? 0 : profileIndex + 1;
    setProfileIndex?.(newIndex);
  };

  if (layout === 'solidBold')
    return (
      <Box
        p={1}
        pt={4.5}
        sx={{ backgroundImage: `linear-gradient(to bottom, ${gradientColors.join(', ')})` }}
        width="100%"
        height="100%"
        overflow="hidden"
        position="relative">
        <SaveContactButton />
        <Box
          display="flex"
          flexDirection="column"
          borderRadius={6}
          py={2}
          bgcolor={solidBgColor}
          sx={{ '&>.MuiBox-root': { px: 3 } }}
          height="85%">
          <AvatarAndName textColor={headerTextColor} />
          <Box position="relative" borderBottom={`1px solid ${linkTextColor}`} pb={2}>
            <Bio width="90%" />
            <Box
              px={1}
              position="absolute"
              border="1px solid white"
              sx={{
                backgroundImage: `linear-gradient(to right, ${gradientColors.join(', ')})`,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                overflow: 'hidden',
                bottom: 0,
                right: 12,
              }}>
              <Typography color={gradientTextColor} fontFamily="Himagsikan" fontSize={isPreview ? 12 : 16}>
                {profileLabel}
              </Typography>
            </Box>
          </Box>
          <Box width="100%" flex={1} display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box
              pb="8%"
              pt="8%"
              width="100%"
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={1}
              overflow="hidden">
              <Typography
                fontFamily="Conthrax, sans-serif"
                fontSize={isPreview ? 12 : 16}
                textAlign="center"
                color={socialIconsColor}>
                FEATURED
              </Typography>
              <Box flex={1} width="100%" display="flex" flexDirection="column" gap={2} justifyContent="space-between">
                <Links />
                <Socials />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
          pb={1}
          height="15%"
          position="relative">
          <Pagination />
          <FooterText />
        </Box>
      </Box>
    );

  return (
    <>
      <Box
        p={1}
        px={2}
        pt={4}
        sx={{ backgroundImage: `url('${coverImage}')`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}
        width="100%"
        height="25%"
        overflow="hidden"
        position="relative"
        display="flex"
        flexDirection="column">
        <svg style={{ position: 'absolute', zIndex: 0, bottom: -70, left: 0 }} width="100%" viewBox="0 0 360 323">
          <path
            fill={solidBgColor}
            d="M 0 114.874 L 0 275 L 425 278.27 L 425 229.881 C 425 229.881 321.889 187.127 220.365 179.284 C 118.841 171.441 43.53 171.73 0 114.874 Z"
          />
        </svg>
        <SaveContactButton />
        <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
          <AvatarAndName textColor="white" containerProps={{ position: 'sticky' }} />
          <Bio width="70%" />
        </Box>
        <Box
          px={1}
          position="absolute"
          border="1px solid white"
          sx={{
            backgroundImage: `linear-gradient(to right, ${gradientColors.join(', ')})`,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflow: 'hidden',
            bottom: 0,
            right: 12,
          }}>
          <Typography color={gradientTextColor} fontFamily="Himagsikan" fontSize={isPreview ? 12 : 16}>
            {profileLabel}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ backgroundImage: `linear-gradient(to bottom, ${gradientColors.join(', ')})` }}
        flex="1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="75%"
        overflow="hidden">
        <Box
          flexShrink={0}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}>
          <Box
            pb="8%"
            pt="8%"
            width="100%"
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            overflow="hidden">
            <Typography
              fontFamily="Conthrax, sans-serif"
              fontSize={isPreview ? 12 : 16}
              textAlign="center"
              color={socialIconsColor}>
              FEATURED
            </Typography>
            <Box width="100%" px={3}>
              <Links />
            </Box>
            <Socials />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap={0.5}
            justifyContent="flex-end"
            alignItems="center"
            pb={2}
            height="20%"
            position="relative">
            <svg
              style={{ position: 'absolute', bottom: isPreview ? -40 : -50, zIndex: 1 }}
              width="100%"
              viewBox="0 0 360 323">
              <path
                fill={paginationDotsColor}
                d="M 0 114.874 L 0 275 L 425 278.27 L 425 229.881 C 425 229.881 321.889 187.127 220.365 179.284 C 118.841 171.441 43.53 171.73 0 114.874 Z"
              />
            </svg>
            <svg
              style={{ position: 'absolute', bottom: isPreview ? -42 : -52, zIndex: 2 }}
              width="100%"
              viewBox="0 0 360 323">
              <path
                fill={solidBgColor}
                d="M 0 114.874 L 0 275 L 425 278.27 L 425 229.881 C 425 229.881 321.889 187.127 220.365 179.284 C 118.841 171.441 43.53 171.73 0 114.874 Z"
              />
            </svg>
            <Pagination />
            <FooterText />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MobileProfileView;
