import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import Preview from './components/Preview';
import useUserProfile from '../../hooks/useUserProfile';

const Profile = () => {
  const { username, handle } = useParams();
  const { status, data } = useUserProfile({ username });

  return (
    <Box height="100vh">
      <Preview status={status} profiles={data.data} username={username} handle={handle ?? ''} />
    </Box>
  );
};

export default Profile;
