import { useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';

import useAppContext from '../../../hooks/useAppContext';
import environments from '../../../utils/environments';

const { SITE_URL } = environments;

const CopyUserProfileLink = () => {
  const [isJustCopied, setIsJustCopied] = useState(false);
  const {
    profileState: { user, profile },
  } = useAppContext();

  const userProfileLink = `${SITE_URL}/${user?.username}/${profile?.handle}`;

  let copyTimeoutRef;
  const copyLink = () => {
    copyTimeoutRef && clearTimeout(copyTimeoutRef);
    setIsJustCopied(true);
    navigator.clipboard.writeText(userProfileLink);
    copyTimeoutRef = setTimeout(() => setIsJustCopied(false), 1000);
  };

  return (
    <TextField
      size="small"
      disabled
      sx={{ mt: 0.5 }}
      value={userProfileLink}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Button size="small" onClick={copyLink} color={isJustCopied ? 'primary' : 'neutral'}>
              {isJustCopied ? 'Copied' : 'Copy'}
            </Button>
          </InputAdornment>
        ),
        sx: {
          p: 0,
          '& .MuiInputBase-input': {
            padding: '4px',
            color: '#565656',
          },
        },
      }}
      variant="outlined"
    />
  );
};

export default CopyUserProfileLink;
