import { Box } from '@mui/material';

import Layout from '../../components/Layout';
import HeadTags from '../../components/HeadTags';
import AccountDetails from './components/AccountDetails';
import ProfilesOrder from './components/ProfilesOrder';
import Security from './components/Security';
import PlanDetails from './components/PlanDetails';

const Profile = () => {
  return (
    <Layout>
      <HeadTags title="Settings" description="Update your account's information" />
      <Box p={2} height="100%">
        <Box px={{ xs: 4, md: 8, lg: 12 }} display="flex" flexDirection="column" gap={4}>
          <AccountDetails />
          <ProfilesOrder />
          <Security />
          <PlanDetails />
        </Box>
      </Box>
    </Layout>
  );
};

export default Profile;
