import { Box, Button, Container, Grid, Typography } from '@mui/material';

import Header from './Header';
import useAuth from '../../../hooks/useAuth';

const Introduction = () => {
  const { uid } = useAuth();

  return (
    <Box
      position="relative"
      minHeight="100vh"
      width="100vw"
      bgcolor="#f9adc2"
      sx={{
        backgroundImage: 'url(/images/landing-1-bg.webp)',
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}>
      <Box
        width="100%"
        minHeight="100vh"
        position="absolute"
        sx={{
          bottom: 0,
          backgroundImage: 'url(/images/landing-1-curve.svg)',
          backgroundSize: { xs: 'auto 60%', md: 'contain' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
        }}
      />
      <Container
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pb: 3,
        }}>
        <Header />
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', md: 'flex-start' }}
              gap={{ xs: 2, md: 4 }}
              px={{ xs: 3, md: 5 }}
              pt={{ xs: 0, md: 5, lg: 10 }}
              color="white">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography
                  textAlign={{ xs: 'center', md: 'left' }}
                  fontSize={{ xs: 32, md: 40, lg: 60 }}
                  fontFamily="TS Block, sans-serif"
                  variant="h1">
                  CONNEXIONS
                </Typography>
                <Typography
                  textAlign={{ xs: 'center', md: 'left' }}
                  fontSize={{ xs: 24, lg: 32 }}
                  fontWeight="bold"
                  fontFamily="Lexend">
                  By MIRL
                </Typography>
              </Box>
              <Typography fontSize={{ xs: 16, lg: 20 }} textAlign={{ xs: 'center', md: 'left' }} fontWeight="bold">
                Say goodbye to cluttered bios and fragmented online identities. Join us in shaping the future of
                personal and professional branding.
              </Typography>
              <Box
                display="flex"
                alignItems={{ xs: 'stretch', md: 'center' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}>
                <Button
                  variant="contained"
                  color="neutral"
                  sx={{
                    width: 300,
                    py: 1.5,
                    borderRadius: 6,
                    textTransform: 'uppercase',
                    fontFamily: 'Lexend, sans-serif',
                    fontWeight: 'bold',
                  }}
                  component="a"
                  href={uid ? '/links' : '/login'}>
                  Join Now
                </Button>
              </Box>
              <Box display="flex" justifyContent="center" gap={2}>
                <a href="https://play.google.com/store/apps" target="_blank" rel="noopener noreferrer">
                  <img src="/images/google-play.png" alt="google-play" width="145px" />
                </a>
                <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/app-store.png" alt="app-store" width="130px" />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-end', sm: 'center', md: 'flex-end' },
              alignItems: 'flex-end',
            }}>
            <Box
              display="flex"
              justifyContent={{ xs: 'flex-end', md: 'flex-end' }}
              alignItems="flex-end"
              height={{ xs: 470, md: '95vh', xl: '80vh' }}
              mr={{ xs: -2, lg: -15 }}>
              <img src="/images/landing-mockup-shadow.webp" alt="MIRL Connexions" style={{ height: '100%' }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Introduction;
