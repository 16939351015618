import { useId, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import AddButton from './AddButton';
import TextInput from '../../../components/TextInput';
import useAppContext from '../../../hooks/useAppContext';
import useUploadFile from '../../../hooks/useUploadFile';
import { createLink, updateLink } from '../../../services/profile.service';
import { defaultSocials, getIconSrc, maxCharCountLinkTitle } from '../../../utils/constants';
import QueryKeys from '../../../utils/queryKeys';

const GMAIL_LINK_PREFIX = 'https://mail.google.com/mail/?view=cm&source=mailto&to=';
const validationSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
  title: yup.string().required('Email title is required').max(maxCharCountLinkTitle),
  icon: yup.string(),
});

const EmailForm = ({ initData, onClose }) => {
  const { uploadFile } = useUploadFile();
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { user, profile },
  } = useAppContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ id, data }) => (id ? updateLink(profile.id, id, data) : createLink(profile.id, data)),
    onSuccess: () => {
      enqueueSnackbar(`${!!initData ? 'Update' : 'Create'} link successfully`, {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Links] });
      onClose();
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const inputId = useId();
  const labelRef = useRef();

  const title = initData ? 'Edit your email' : `Insert your email`;
  const social = defaultSocials.find((item) => item.name === 'Email');

  const initialValues = initData
    ? {
        email: initData.url.replace(GMAIL_LINK_PREFIX, '') || user?.email,
        title: initData.title || '',
        icon: initData.icon || '',
      }
    : {
        email: user?.email,
        title: '',
        icon: social?.icon || '',
      };

  const handleInputChange = (e) => {
    setFile(e.target.files[0]);
    e.target.value = '';
  };

  const onSubmit = async (values) => {
    try {
      values.type = 'Email';
      if (file) {
        try {
          setIsUploading(true);
          const { storageRef, url } = await uploadFile(file, initData?.iconStorageRef, 'link-icons');
          values.icon = url;
          values.iconStorageRef = storageRef;
          setIsUploading(false);
        } catch (err) {
          enqueueSnackbar(err.message, { variant: 'error' });
          throw new Error(err.message);
        }
      }
      const { email, ...rest } = values;
      await mutation.mutateAsync({
        id: initData?.id,
        data: { ...rest, url: `${GMAIL_LINK_PREFIX}${email.toLowerCase()}` },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    // validateOnChange: false,
  });

  const removeIcon = () => {
    setFile(null);
    setFieldValue('icon', '');
  };

  const iconSrc = file ? URL.createObjectURL(file) : values.icon || getIconSrc(`black/Email_Icon.png`);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <label htmlFor={inputId} ref={labelRef} style={{ display: 'none' }} />
      <input
        id={inputId}
        style={{ display: 'none' }}
        type="file"
        multiple
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleInputChange}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between" pr={3}>
        <Box>
          <Typography fontSize="20px" fontWeight={500}>
            {title}
          </Typography>
          <Typography fontSize="12px">Enter your URL and give your URL a title.</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        {iconSrc ? (
          <Box>
            <img
              src={iconSrc}
              alt="icon"
              style={{
                display: 'block',
                width: '70px',
                aspectRatio: '1/1',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        ) : (
          <Box
            width="70px"
            sx={{ aspectRatio: '1/1' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={2}
            border={`1px solid ${grey[300]}`}>
            <Typography fontSize="12px" align="center" color={grey[600]}>
              No icon.
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              py: 0.5,
              px: 1,
              fontSize: '10px',
              textTransform: 'none',
            }}
            onClick={() => labelRef.current?.click()}
            disabled={mutation.isLoading}>
            Change icon
          </Button>
          {(file || values.icon) && (
            <Button
              variant="contained"
              color="error"
              sx={{
                py: 0.5,
                px: 1,
                fontSize: '10px',
                textTransform: 'none',
              }}
              onClick={removeIcon}
              disabled={mutation.isLoading}>
              Remove icon
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <TextInput
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Email *required*"
          bgcolor="#f3f3f1"
          color="black"
          showError
          error={touched.email && errors.email}
        />
        <TextInput
          name="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Email title *required*"
          bgcolor="#f3f3f1"
          color="black"
          showError
          error={touched.title && errors.title}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <AddButton
          text={!!initData ? 'Update' : 'Add'}
          onClick={handleSubmit}
          disabled={!isValid || mutation.isLoading || isUploading}
        />
      </Box>
    </Box>
  );
};

export default EmailForm;
