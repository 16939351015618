import { useEffect, useRef, useState } from 'react';
import { Dialog, Box, Autocomplete, TextField, Button, Typography, IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';

import useAppContext from '../../../hooks/useAppContext';
import { createSocial, updateSocial } from '../../../services/profile.service';
import { extendedSocials } from '../../../utils/constants';
import QueryKeys from '../../../utils/queryKeys';

const validationSchema = yup.object({
  url: yup.string().required('URL is required'),
});

const CreateSocialModal = ({ open, onClose, initData }) => {
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    profileState: { profile },
  } = useAppContext();
  const title = initData ? 'Edit your social' : `Add your social`;
  const initialValues = initData
    ? {
        url: initData.url || '',
      }
    : {
        url: '',
      };

  const timeout = useRef();
  useEffect(() => {
    if (!open) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(reset, 500);
    } else if (initData) {
      setValue({ label: initData.type });
    }
    // eslint-disable-next-line
  }, [open, initData]);

  const mutation = useMutation({
    mutationFn: ({ id, data }) => (id ? updateSocial(profile.id, id, data) : createSocial(profile.id, data)),
    onSuccess: () => {
      enqueueSnackbar(`${!!initData ? 'Update' : 'Add'} social successfully`, {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Socials] });
      onClose();
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      values.type = value.label;
      await mutation.mutateAsync({ id: initData?.id, data: values });
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    // validateOnChange: false,
  });

  const reset = () => {
    setValue(null);
    resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      PaperProps={{ sx: { overflowY: 'visible' } }}>
      <Box
        p={3}
        pb={1}
        bgcolor="rgba(211, 51, 121, 0.25);"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={1}
        position="relative">
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography fontSize="20px" fontWeight={500}>
          {title}
        </Typography>
        <Autocomplete
          disablePortal
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          options={extendedSocials.map(({ name }) => ({ label: name }))}
          sx={{ width: '100%', bgcolor: '#f3f3f1', borderRadius: 1 }}
          renderInput={(params) => <TextField {...params} size="small" placeholder="Search social" />}
        />
        {value ? (
          <Box display="flex" alignItems="flex-start" width="100%" gap={2}>
            <TextField
              name="url"
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              placeholder={`Enter ${value.label} url:`}
              sx={{ flex: 1, '& input': { bgcolor: 'white', borderRadius: 1 } }}
              error={touched.url && errors.url}
              helperText={(touched.url && errors.url) || ' '}
            />
            <Button variant="contained" onClick={handleSubmit} disabled={(touched.url && errors.url) || isLoading}>
              Save
            </Button>
          </Box>
        ) : (
          <Button variant="contained" sx={{ mb: 3.5 }}>
            Search
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default CreateSocialModal;
