import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';

import CreateProfileModal from './CreateProfileModal';
import useAppContext from '../hooks/useAppContext';
import { auth } from '../configs/firebase.config';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const navigate = useNavigate();
  const {
    profileState: { user, profile, setProfileIndex },
    createProfileModalState: { setIsCreateProfileModalOpen },
  } = useAppContext();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const maximumProfileCountReached = useMemo(
    () => user?.planId === 'free' && user?.profiles?.length === 2,
    [user?.planId, user?.profiles?.length]
  );

  const logout = () => {
    signOut(auth);
    navigate('/login');
  };

  return (
    <Box height="85px" px={2} display="flex" alignItems="center" justifyContent="space-between">
      <Typography fontFamily="Himagsikan, sans-serif !important" fontSize={{ xs: 24, md: 32 }}>
        Connexions
      </Typography>
      <CreateProfileModal />
      {profile && (
        <Box display="flex" alignItems="center" gap={1}>
          <Box display="flex" gap={{ xs: 0.5, md: 1 }} sx={{ cursor: 'pointer' }} onClick={handleClick}>
            <Box display="flex" alignItems="center" gap={{ xs: 0.5, md: 1 }}>
              <Typography fontWeight={700}>{profile?.handle}</Typography>
              <KeyboardArrowDownIcon />
            </Box>
            <Box width={{ xs: 36, md: 50 }} height={{ xs: 32, md: 50 }} borderRadius="50%" overflow="hidden">
              <img
                src={profile?.avatar || '/images/default-avatar.webp'}
                alt="avatar"
                style={{
                  width: '100%',
                  aspectRatio: '1/1',
                  display: 'block',
                }}
              />
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              mt: 2,
              '& .MuiMenu-paper': { width: '200px' },
              '& ul': { p: 0 },
            }}>
            {user.profiles.map((item, index) => (
              <MenuItem
                key={item.id}
                onClick={() => {
                  setProfileIndex(index);
                  handleClose();
                }}>
                <Box display="flex" alignItems="center" gap={1} p={1}>
                  <Box width="40px" height="40px" borderRadius="50%" overflow="hidden">
                    <img
                      src={item.avatar || '/images/default-avatar.webp'}
                      alt="avatar"
                      style={{
                        width: '100%',
                        aspectRatio: '1/1',
                        display: 'block',
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography lineHeight={1}>{item.name}</Typography>
                    <Typography fontSize={12} lineHeight={1}>
                      @{item.handle}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
            <MenuItem
              onClick={() => {
                !maximumProfileCountReached && setIsCreateProfileModalOpen(true);
                handleClose();
              }}
              sx={{ position: 'relative' }}>
              <Box display="flex" alignItems="center" gap={1} p={1}>
                <Box
                  width="40px"
                  height="40px"
                  borderRadius="50%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  bgcolor="black">
                  <PersonAddIcon htmlColor="white" />
                </Box>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography>Add Profile</Typography>
                </Box>
              </Box>
              {maximumProfileCountReached && (
                <Box
                  position="absolute"
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  bgcolor="rgba(217, 217, 217, 0.95)"
                  p={1}>
                  <LockOutlinedIcon fontSize="small" color="primary" />
                  <Typography fontSize={12} fontWeight={700} textAlign="center" color="primary">
                    Need more profile?
                    <br />
                    Unlock Premium
                  </Typography>
                </Box>
              )}
            </MenuItem>
            <MenuItem onClick={logout} sx={{ display: { md: 'none' } }}>
              <Box display="flex" alignItems="center" gap={1} p={1}>
                <Box width="40px" display="flex" justifyContent="center" alignItems="center">
                  <LogoutIcon />
                </Box>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography>Log out</Typography>
                </Box>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default Header;
