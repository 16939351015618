import api from './api';

export const getAnalytics = ({ profileId, start, end }) =>
  api.get(`/api/v1/analytics/${profileId}`, { params: { start, end } });

export const viewProfile = ({ profileId, utm_medium, utm_source }) =>
  api.post(`/api/v1/analytics/${profileId}/view-profile`, { utm_medium, utm_source });

export const clickLink = ({ profileId, linkId, sessionId }) =>
  api.post(`/api/v1/analytics/${profileId}/click-link`, { linkId, sessionId });

export const clickSocial = ({ profileId, socialId, sessionId }) =>
  api.post(`/api/v1/analytics/${profileId}/click-social`, { socialId, sessionId });
