import { useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import PlanModal from './PlanModal';
import useProfile from '../../../hooks/useProfile';
import { updatePlan } from '../../../services/user.service';
import QueryKeys from '../../../utils/queryKeys';

const PlanDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { user } = useProfile();
  const queryClient = useQueryClient();
  const planMutation = useMutation({
    mutationFn: ({ id }) => updatePlan({ planId: id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Update plan successfully', {
        variant: 'success',
      });
      setOpen(false);
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const { plan } = user || {};

  const onSelectPlan = async (id) => {
    try {
      await planMutation.mutateAsync({ id });
    } catch (err) {
      console.error(err);
    }
  };

  if (!user) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PlanModal open={open} onClose={() => setOpen(false)} currentPlanId={plan?.id} onSelect={onSelectPlan} />
      <Typography fontSize="20px" fontWeight={500}>
        Plan Details
      </Typography>
      <Box bgcolor="white" p={2} borderRadius={2} display="flex" flexDirection="column" gap={2}>
        <TextField
          variant="standard"
          label="Plan"
          value={plan?.name}
          disabled
          // InputProps={{
          //   endAdornment: (
          //     <Box pb={1}>
          //       <InputButton text="Upgrade Plan" onClick={() => setOpen(true)} />
          //     </Box>
          //   ),
          // }}
        />
      </Box>
    </Box>
  );
};

export default PlanDetails;
