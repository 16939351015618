import { Button, alpha } from '@mui/material';

const AddButton = ({ text = 'Add', onClick, disabled }) => (
  <Button
    variant="contained"
    sx={{
      alignSelf: 'flex-end',
      height: '42px',
      borderRadius: 2.5,
      bgcolor: 'black',
      color: 'white',
      '&:hover': {
        bgcolor: alpha('#000', 0.8),
      },
    }}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </Button>
);

export default AddButton;
