import environments from './environments';

const { BACKEND_URL } = environments;
export const getIconSrc = (src) => `${BACKEND_URL}/icons/${src}`;

export const maxCharCountLinkTitle = 25;
// for links section
export const defaultSocials = [
  'Twitter',
  'Instagram',
  'TikTok',
  'Snapchat',
  'Discord',
  'WhatsApp',
  'LinkedIn',
  'Opensea',
  'Pinterest',
  'Website',
  'Email',
];

const socialsExtended = [
  'Behance',
  'Discord',
  'Dropbox',
  'Email',
  'Facebook',
  'Flickr',
  'Instagram',
  'Kakao',
  'Line',
  'LinkedIn',
  'Medium',
  'Opensea',
  'Pinterest',
  'Reddit',
  'Skype',
  'Snapchat',
  'Soundcloud',
  'Spotify',
  'Telegram',
  'TikTok',
  'Twitch',
  'Twitter',
  'Wechat',
  'WhatsApp',
  'Youtube',
];

// for socials section
export const extendedSocials = socialsExtended.map((name) => ({ name }));
