import api from './api';

export const register = (data) => api.post('/api/v1/auth/register', data);

export const requestResetPassword = (data) => api.post('/api/v1/auth/forgot-password', data);

export const resetPassword = (id, password) => api.put(`/api/v1/auth/reset-password/${id}`, { password });

export const verify = (id) => api.put(`/api/v1/auth/verify/${id}`);

export const verifyUser = (id) => api.put(`/api/v1/auth/verify/user/${id}`);
