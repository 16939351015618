import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DeleteProfileModal from './DeleteProfileModal';
import useAppContext from '../../../hooks/useAppContext';
import { remove } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';

const RemoveProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile, setProfileIndex },
  } = useAppContext();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: remove,
    onSuccess: () => {
      setProfileIndex(0);
      enqueueSnackbar('Removed profile successfully', { variant: 'success' });
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'warning' });
    },
  });

  const onRemove = async () => {
    try {
      await mutation.mutateAsync(profile?.id);
    } catch (err) {
      console.error(err);
    }
  };

  if (!profile) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <DeleteProfileModal
        open={open}
        onClose={() => setOpen(false)}
        onRemove={onRemove}
        disabled={mutation.isLoading}
      />
      <Typography fontSize="20px" fontWeight={500}>
        Delete Profile
      </Typography>
      <Box bgcolor="white" p={2} borderRadius={2} display="flex" flexDirection="column" gap={2}>
        <Typography>PLEASE NOTE: Once profile is deleted, it cannot be undone. </Typography>
        <Button
          variant="contained"
          color="error"
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            py: 0.5,
            textTransform: 'none',
            alignSelf: 'flex-start',
          }}
          onClick={() => setOpen(true)}>
          Delete Profile
        </Button>
      </Box>
    </Box>
  );
};

export default RemoveProfile;
