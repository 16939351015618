import { useState, useId, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import AddButton from './AddButton';
import TextInput from '../../../components/TextInput';
import useUploadFile from '../../../hooks/useUploadFile';
import useAppContext from '../../../hooks/useAppContext';
import { createLink, updateLink } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';
import { getIconSrc, maxCharCountLinkTitle } from '../../../utils/constants';

const validationSchema = yup.object({
  url: yup.string().required('URL is required'),
  title: yup.string().required('Link title is required').max(maxCharCountLinkTitle),
  icon: yup.string(),
});

const CustomForm = ({ initData, type, initUrl = '', onClose }) => {
  const { uploadFile } = useUploadFile();
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile },
  } = useAppContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ id, data }) => (id ? updateLink(profile.id, id, data) : createLink(profile.id, data)),
    onSuccess: () => {
      enqueueSnackbar(`${!!initData ? 'Update' : 'Create'} link successfully`, {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Links] });
      onClose();
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const inputId = useId();
  const labelRef = useRef();

  const initialValues = initData
    ? {
        url: initData.url || '',
        title: initData.title || '',
        icon: initData.icon || '',
      }
    : {
        url: initUrl,
        title: '',
        icon: '',
      };

  const handleInputChange = (e) => {
    setFile(e.target.files[0]);
    e.target.value = '';
  };

  const title = initData ? 'Edit your link' : `Add your ${type} link`;

  const onSubmit = async (values) => {
    try {
      values.type = type || 'custom';
      if (file) {
        try {
          setIsUploading(true);
          const { storageRef, url } = await uploadFile(file, initData?.iconStorageRef, 'link-icons');
          values.icon = url;
          values.iconStorageRef = storageRef;
          setIsUploading(false);
        } catch (err) {
          enqueueSnackbar(err.message, { variant: 'error' });
          throw new Error(err.message);
        }
      }
      await mutation.mutateAsync({ id: initData?.id, data: values });
    } catch (err) {
      console.error(err);
    }
  };

  const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    // validateOnChange: false,
  });

  const removeIcon = () => {
    setFile(null);
    setFieldValue('icon', '');
  };

  const iconSrc = file
    ? URL.createObjectURL(file)
    : values.icon || (type !== 'custom' && getIconSrc(`black/${type}_Icon.png`));

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <label htmlFor={inputId} ref={labelRef} style={{ display: 'none' }} />
      <input
        id={inputId}
        style={{ display: 'none' }}
        type="file"
        multiple
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleInputChange}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography fontSize="20px" fontWeight={500}>
            {title}
          </Typography>
          <Typography fontSize="12px">Enter your URL and give your URL a title.</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        {iconSrc ? (
          <Box>
            <img
              src={iconSrc}
              alt="icon"
              style={{
                display: 'block',
                width: '70px',
                aspectRatio: '1/1',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        ) : (
          <Box
            width="70px"
            sx={{ aspectRatio: '1/1' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={2}
            border={`1px solid ${grey[300]}`}>
            <Typography fontSize="12px" align="center" color={grey[600]}>
              No icon.
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              py: 0.5,
              px: 1,
              fontSize: '10px',
              textTransform: 'none',
            }}
            onClick={() => labelRef.current?.click()}
            disabled={mutation.isLoading}>
            {iconSrc ? 'Change' : 'Upload'} icon
          </Button>
          {(file || values.icon) && (
            <Button
              variant="contained"
              color="error"
              sx={{
                py: 0.5,
                px: 1,
                fontSize: '10px',
                textTransform: 'none',
              }}
              onClick={removeIcon}
              disabled={mutation.isLoading}>
              Remove icon
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <TextInput
          name="url"
          value={values.url}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="URL *required*"
          bgcolor="#f3f3f1"
          color="black"
          showError
          error={touched.url && errors.url}
        />
        <TextInput
          name="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Link title *required*"
          bgcolor="#f3f3f1"
          color="black"
          showError
          error={touched.title && errors.title}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <AddButton
          text={initData ? 'Update' : 'Add'}
          onClick={handleSubmit}
          disabled={!iconSrc || !isValid || mutation.isLoading || isUploading}
        />
      </Box>
    </Box>
  );
};

export default CustomForm;
