import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import QRCode from 'react-qr-code';

import useAppContext from '../../../hooks/useAppContext';
import environments from '../../../utils/environments';

const { SITE_URL } = environments;

const ShareLinkQRMenu = ({ setCurrentPage }) => {
  const {
    profileState: { user, profile },
  } = useAppContext();

  const userProfileLink = `${SITE_URL}/${user?.username}/${profile?.handle}`;

  const download = () => {
    const svg = document.querySelector('.profile-qr > svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const svgData = svg.outerHTML;
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';
    const svgBlob = new Blob([preface, svgData], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = svgUrl;
    downloadLink.download = `${user?.username}_${profile?.username}_MIRL-Connexions`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Typography
        fontWeight="bold"
        fontSize="18px"
        sx={{ padding: '0 16px 6px 6px' }}
      >
        <IconButton onClick={() => setCurrentPage('default')} sx={{ mr: 0.5 }}>
          <NavigateBeforeIcon />
        </IconButton>
        My Connexions QR
      </Typography>
      <Divider />
      <Box
        p={1}
        gap={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="profile-qr"
      >
        <QRCode value={userProfileLink} size={200} />
        <Button
          variant="outlined"
          color="neutral"
          size="small"
          onClick={download}
          id="download-qr-btn"
        >
          Save QR
        </Button>
      </Box>
    </>
  );
};

export default ShareLinkQRMenu;
