import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TextInput from '../../../components/TextInput';
import { checkExisted } from '../../../services/user.service';

const initialValues = {
  name: '',
  username: '',
};

const Names = ({ submit, loading }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const cachedUsernames = useRef([]);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    username: yup
      .string()
      .required('Username is required')
      .test('existed', 'Username existed', async (value) => {
        const cached = cachedUsernames.current.find((item) => item.username === value);
        if (cached) return cached.valid;
        setIsLoading((prev) => true);
        let valid = true;
        try {
          await checkExisted({ username: value });
          cachedUsernames.current.push({ username: value, valid: true });
        } catch (err) {
          cachedUsernames.current.push({ username: value, valid: false });
          valid = false;
        }
        setIsLoading((prev) => false);
        return valid;
      }),
  });

  const onSubmit = (values) => {
    submit(values);
  };

  const { values, touched, errors, isValid, setFieldValue, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      gap={{ xs: 2, md: 4 }}
      py={4}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
        <Typography fontSize="28px" fontWeight={700} align="center">
          Great work!
        </Typography>
        <Typography fontSize="20px" align="center">
          Now let&apos;s begin creating your unique Connexions profile.
          <br /> Please register a unique profile name, and choose a preferred public display name.
          <br /> <br />
          These can be changed later.
        </Typography>
      </Box>
      <Box width={{ xs: '80vw', sm: '400px' }} display="flex" flexDirection="column" alignItems="center">
        <TextInput
          name="username"
          label="Unique user profile name"
          placeholder="Username"
          value={values.username}
          onChange={(e) => setFieldValue('username', e.target.value.trim())}
          onBlur={handleBlur}
          showError
          error={touched.username && errors.username}
          StartIcon={
            <Typography fontSize="12px" color="white">
              @
            </Typography>
          }
        />
        <TextInput
          label="Public profile display name"
          placeholder="example 1: MIRLOfficial / example 2: MIRL Official"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          showError
          error={touched.name && errors.name}
        />
        <Button
          sx={{
            mt: 2,
            px: 6,
            borderRadius: 5,
            border: `2px solid ${theme.colors.main}`,
            color: 'black',
            bgcolor: 'white',
            textTransform: 'none',
            fontWeight: 500,
          }}
          disabled={!isValid || loading || isLoading}
          onClick={handleSubmit}>
          Confirm
        </Button>
      </Box>
      <Button variant="link" onClick={() => navigate('/')} sx={{ alignSelf: 'flex-start', textTransform: 'none' }}>
        {'< Back to homepage'}
      </Button>
    </Box>
  );
};

export default Names;
