import { Box } from '@mui/material';

import MobileProfileView from './MobileProfileView';
import useAppContext from '../hooks/useAppContext';

const DevicePreview = ({
  currentBio,
  currentProfileLabel,
  currentName,
  currentHandle,
  currentAvatar,
  currentIsVcardEnabled,
  selectedTheme,
  selectedLayout,
  links,
  socials,
}) => {
  const {
    profileState: { user, profile, profileIndex },
  } = useAppContext();
  const { theme, layoutId } = profile || {};

  if (!profile || !theme) return null;

  return (
    <Box position="relative" className="user-profile">
      <Box position="absolute" zIndex={1} top={0} left={0} width="min(100%, 480px)" sx={{ aspectRatio: '380/646' }}>
        <img src="/images/device-frame.png" alt="frame" style={{ width: '100%', display: 'block' }} />
      </Box>
      <Box
        position="sticky"
        zIndex={2}
        pl="min(14.75%, 70.8px)"
        pr="min(14%, 76px)"
        pt="min(4%, 23px)"
        pb="10%"
        width="min(100%, 489px)"
        sx={{ aspectRatio: '380/646' }}>
        <Box borderRadius={8} sx={{ overflow: 'hidden' }}>
          <MobileProfileView
            {...{
              isPreview: true,
              bio: currentBio ?? profile.bio,
              profileLabel: currentProfileLabel ?? profile.profileLabel,
              name: currentName ?? profile.name,
              handle: currentHandle ?? profile.handle,
              avatar: currentAvatar ? URL.createObjectURL(currentAvatar) : profile.avatar,
              isVcardEnabled: currentIsVcardEnabled ?? profile.isVcardEnabled,
              theme: selectedTheme ?? theme,
              layoutId: selectedLayout?.id ?? layoutId,
              links,
              socials,
              profiles: user.profiles,
              profileIndex,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DevicePreview;
