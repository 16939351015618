import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import HeadTags from '../../components/HeadTags';
import AuthLayout from '../../components/AuthLayout';
import { verifyUser } from '../../services/auth.service';

const Success = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const uid = searchParams.get('uid');

  const resend = async () => {
    if (loading || !uid) return;
    setLoading(true);

    try {
      await verifyUser(uid);
      enqueueSnackbar('Verify email is sent!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <HeadTags
        title="Register Success"
        description="Verify your email to start promoting your brand with Connexions"
      />
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        py={10}>
        <Box
          width={{ xs: '80vw', md: '800px' }}
          maxWidth="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}>
          <Typography fontSize="24px" fontWeight={500} align="center">
            Success!
          </Typography>
          <Typography fontWeight={500} align="center">
            An email has been sent to your email to verify your account. <br />
            Click on the link in the email to complete your sign up.
          </Typography>
          <Typography fontWeight={500} align="center">
            Upon verification, you will be able to set up your NFC ring
          </Typography>
          <Typography fontSize="14px" fontWeight={500}>
            Haven&apos;t received any emails? Send another link{' '}
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: theme.colors.activeLink,
              }}
              onClick={resend}>
              here
            </span>
          </Typography>
        </Box>
        <Button variant="link" onClick={() => navigate('/')} sx={{ alignSelf: 'flex-start', textTransform: 'none' }}>
          {'< Back to homepage'}
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default Success;
