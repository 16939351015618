import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { defaultSocials, getIconSrc } from '../../../utils/constants';
import TextInput from '../../../components/TextInput';
import AddButton from './AddButton';

const GAP = 1;
const ITEM_PER_ROW = 6;

const IconList = ({ customUrl, setCustomUrl, setType }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize="20px" fontWeight={500}>
        Quick Icons
      </Typography>
      <Box display="flex" alignItems="center" gap={GAP} flexWrap="wrap">
        {defaultSocials.map((name) => (
          <Box
            key={name}
            p={1}
            width={`calc((100% - ${ITEM_PER_ROW - 1} * ${GAP} * 8px) / ${ITEM_PER_ROW})`}
            display="flex"
            flexDirection="column"
            gap={0.5}
            borderRadius={2}
            sx={{
              cursor: 'pointer',
              transition: 'all ease 0.3s',
              '&:hover': {
                bgcolor: grey[200],
              },
            }}
            onClick={() => setType(name)}>
            <img
              src={getIconSrc(`black/${name}_Icon.png`)}
              alt="icon"
              style={{
                width: '100%',
                aspectRatio: '1/1',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 12,
              }}
            />
            <Typography fontSize="12px" align="center">
              {name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" gap={2}>
        <TextInput
          label="Customized URL"
          value={customUrl}
          onChange={(e) => setCustomUrl(e.target.value)}
          placeholder="https://"
          bgcolor="#f3f3f1"
          color="black"
        />
        <AddButton
          onClick={() => {
            if (customUrl.trim()) {
              setType('custom');
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default IconList;
