import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { verify } from '../../services/auth.service';
import QueryKeys from '../../utils/queryKeys';
import AuthLayout from '../../components/AuthLayout';
import HeadTags from '../../components/HeadTags';

const Verify = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { activateRequestId } = useParams();
  const [verifyStatus, setVerifyStatus] = useState(null);
  const [count, setCount] = useState(5);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ id }) => verify(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
    },
    onError: (err) => {},
  });

  const activate = async () => {
    if (!activateRequestId || verifyStatus?.status === 'loading') return;
    setVerifyStatus({ status: 'loading' });
    try {
      await mutation.mutateAsync({ id: activateRequestId });
      setVerifyStatus({ status: 'success' });
    } catch (err) {
      console.error(err);
      setVerifyStatus({ status: 'error', error: err.message });
    }
  };

  useEffect(() => {
    activate();
    // eslint-disable-next-line
  }, []);

  const timeout = useRef();
  useEffect(() => {
    if (verifyStatus?.status === 'success' && count > 0) {
      timeout.current = setTimeout(() => setCount(count - 1), 1000);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [verifyStatus, count]);

  useEffect(() => {
    if (count === 0) {
      navigate('/login?verified=true');
    }
  }, [count, navigate]);

  useEffect(() => {
    return () => timeout.current && clearTimeout(timeout.current);
  }, []);

  const render = () => {
    if (activateRequestId) {
      if (!verifyStatus) return null;
      const { status, error } = verifyStatus;
      if (status === 'loading') return <Typography align="center">Verifying your account...</Typography>;
      if (status === 'error')
        return (
          <>
            <ReportProblemIcon sx={{ fontSize: '40px', color: theme.palette.error.main }} />
            <Typography color="error" align="center">
              {error}
            </Typography>
          </>
        );
      if (status === 'success')
        return (
          <>
            <CheckCircleIcon sx={{ fontSize: '40px', color: theme.palette.success.main }} />
            <Typography align="center">
              Your account is verified.
              <br /> Auto redirect to main page in {count} seconds
            </Typography>
            <Button
              variant="link"
              onClick={() => navigate('/login?verified=true')}
              sx={{ textTransform: 'none', color: theme.palette.primary.main }}>
              Back to main page
            </Button>
          </>
        );
    }
  };

  return (
    <AuthLayout>
      <HeadTags title="Verifying Email" description="Verify your email to start promoting your brand with Connexions" />
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        {render()}
      </Box>
    </AuthLayout>
  );
};

export default Verify;
