import { Button, alpha } from '@mui/material';

const InputButton = ({ text, onClick, bgcolor = '#000', disabled }) => (
  <Button
    variant="contained"
    sx={{
      width: '100px',
      fontSize: '12px',
      fontWeight: 400,
      p: 0.5,
      bgcolor,
      color: 'white',
      textTransform: 'none',
      '&:hover': { bgcolor: alpha(bgcolor, 0.8) },
    }}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </Button>
);

export default InputButton;
