import api from './api';

export const getLinks = (profileId) => api.get(`/api/v1/me/${profileId}/links`);

export const createLink = (profileId, data) => api.post(`/api/v1/me/${profileId}/links`, data);

export const updateLinkOrders = (profileId, data) => api.put(`/api/v1/me/${profileId}/links-order`, data);

export const updateLink = (profileId, linkId, data) => api.put(`/api/v1/me/${profileId}/links/${linkId}`, data);

export const removeLink = (profileId, linkId) => api.delete(`/api/v1/me/${profileId}/links/${linkId}`);

export const getSocials = (profileId) => api.get(`/api/v1/me/${profileId}/socials`);

export const createSocial = (profileId, data) => api.post(`/api/v1/me/${profileId}/socials`, data);

export const updateSocialOrders = (profileId, data) => api.put(`/api/v1/me/${profileId}/socials-order`, data);

export const updateSocial = (profileId, socialId, data) => api.put(`/api/v1/me/${profileId}/socials/${socialId}`, data);

export const removeSocial = (profileId, socialId) => api.delete(`/api/v1/me/${profileId}/socials/${socialId}`);

export const updateBio = (profileId, data) => api.put(`/api/v1/me/${profileId}/bio`, data);

export const updateTheme = (profileId, data) => api.put(`/api/v1/me/${profileId}/theme`, data);

export const updateLayout = (profileId, data) => api.put(`/api/v1/me/${profileId}/layout`, data);

export const getProfile = () => api.get('/api/v1/me');

export const addProfile = () => api.post('/api/v1/me/profile');

export const getUserProfile = ({ username }) => api.get(`/api/v1/profiles/${username}`);

export const updateProfile = (profileId, data) => api.put(`/api/v1/me/profile/${profileId}`, data);

export const remove = (profileId) => api.delete(`/api/v1/me/profile/${profileId}`);

export const checkExisted = (data) => api.post('/api/v1/profiles/existed', data);
