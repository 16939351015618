import { useEffect, useMemo } from 'react';
import { Box, Typography, TextField, Switch, Autocomplete, Button, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from 'formik';

import countries from '../../../assets/jsons/countryPhoneCodes.json';
import useAppContext from '../../../hooks/useAppContext';

const numberRegExp = /^[0-9]*$/;
const minimumPhone = 8;
const singapore = countries.find((country) => country.iso === 'SG');

const ContactDetails = ({ isVcardEnabled, setIsVcardEnabled, profileMutation }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile },
  } = useAppContext();

  const initialValues = useMemo(() => {
    const currentCountry = countries.find((country) => country.code === profile?.vcardCallingCode?.slice(1));

    return {
      name: profile?.vcardName || profile?.name || '',
      phone: profile?.vcardPhone || '',
      email: profile?.vcardEmail || '',
      country: currentCountry || singapore,
    };
  }, [profile]);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    phone: yup
      .string()
      .test('required', 'At least email or phone number is required', (value, context) => {
        if (!isVcardEnabled || !!context.parent.email?.trim()) return true;
        return !!value?.trim();
      })
      .test('valid', 'Invalid phone number', (value, context) => {
        if (!isVcardEnabled || !!context.parent.email?.trim()) return true;
        return value?.match(numberRegExp);
      })
      .test('valid', `It should be at least ${minimumPhone} numbers`, (value, context) => {
        if (!isVcardEnabled || !!context.parent.email?.trim()) return true;
        return value && value.length >= minimumPhone;
      }),
    email: yup
      .string()
      .email('Invalid email')
      .test('required', 'At least email or phone number is required', (value, context) => {
        if (!isVcardEnabled || !!context.parent.phone?.trim()) return true;
        return !!value?.trim();
      }),
  });

  useEffect(() => {
    setIsVcardEnabled(profile?.isVcardEnabled);
    // eslint-disable-next-line
  }, [profile?.isVcardEnabled]);

  const onSubmit = async (values) => {
    try {
      const { name, phone, email, country } = values;
      await profileMutation.mutateAsync({
        vcardName: name,
        vcardPhone: phone,
        vcardEmail: email,
        vcardCallingCode: `+${country.code}`,
        handle: profile?.handle,
      });
      enqueueSnackbar('Update profile successfully', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const toggleIsVcardEnabled = async () => {
    try {
      const newValue = !isVcardEnabled;
      setIsVcardEnabled(newValue);
      await profileMutation.mutateAsync({ isVcardEnabled: newValue, handle: profile?.handle });
    } catch (err) {
      console.error(err);
    }
  };

  const { values, touched, errors, isValid, setFieldValue, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const isSomethingChanged = useMemo(
    () =>
      values.name !== profile?.vcardName ||
      values.country?.code !== profile?.vcardCallingCode?.slice(1) ||
      values.phone !== profile?.vcardPhone ||
      values.email !== profile?.vcardEmail,
    [values, profile?.vcardName, profile?.vcardCallingCode, profile?.vcardPhone, profile?.vcardEmail]
  );

  if (!profile) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize="20px" fontWeight={500}>
          Let visitors save your contact
        </Typography>
        <Switch checked={isVcardEnabled} onChange={toggleIsVcardEnabled} inputProps={{ 'aria-label': 'controlled' }} />
      </Box>
      {isVcardEnabled && (
        <>
          <Box bgcolor="white" p={2} borderRadius={2} display="flex" flexDirection="column">
            <TextField
              variant="standard"
              label="Contact Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && errors.name}
              helperText={(touched.name && errors.name) || ' '}
              sx={{ pb: touched.name && errors.name ? 0.15 : 0 }}
            />
            <Box display="flex" gap={2}>
              <Autocomplete
                sx={{ width: 180 }}
                options={countries}
                autoHighlight
                value={values.country}
                onChange={(event, newValue) => {
                  setFieldValue('country', newValue);
                }}
                getOptionLabel={(option) => option.country}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 1, flexShrink: 0 }, px: '12px !important' }}
                    {...props}
                    value={option.iso}>
                    <img
                      loading="lazy"
                      width="16"
                      srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                      alt=""
                    />
                    <Typography fontSize={14}>
                      {option.country} (+{option.code})
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => <TextField {...params} label="Country" variant="standard" />}
              />
              <TextField
                variant="standard"
                label="Contact Phone Number"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && errors.phone}
                helperText={(touched.phone && errors.phone) || ' '}
                sx={{ pb: touched.phone && errors.phone ? 0.15 : 0 }}
                InputProps={{
                  startAdornment: values.country?.code && (
                    <InputAdornment position="start">+{values.country?.code}</InputAdornment>
                  ),
                }}
              />
            </Box>
            <TextField
              variant="standard"
              label="Contact Email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && errors.email}
              helperText={(touched.email && errors.email) || ' '}
              sx={{ pb: touched.email && errors.email ? 0.15 : 0 }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="neutral"
              onClick={handleSubmit}
              disabled={profileMutation.isLoading || !isValid || !isSomethingChanged}>
              Update Contact
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ContactDetails;
