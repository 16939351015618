import { useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

const Features = () => {
  const [showSecondProfile, setShowSecondProfile] = useState(false);

  return (
    <Box id="features" bgcolor="black">
      <Container sx={{ height: '100vh', py: { xs: 4, lg: 8 }, px: 2 }}>
        <Box display="flex" height="100%" alignItems={{ xs: 'flex-start', md: 'center' }} position="relative">
          <Box width={{ xs: '100%', md: '75%' }} height={{ xs: '50vh', md: 'unset' }} position="relative">
            <img src="/images/landing-splash.png" alt="" width="100%" />
            <img
              src="/images/landing-mockup-1.png"
              alt=""
              height="100%"
              style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
            />
            <img
              src="/images/landing-mockup-2.png"
              alt=""
              height="100%"
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                opacity: showSecondProfile ? 1 : 0,
                transition: 'all 0.3s ease',
              }}
            />
          </Box>
          <Box
            position="absolute"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
            py={{ xs: 0, md: 6 }}
            gap={{ xs: 1, md: 5 }}
            sx={{ left: { xs: 0, md: 'unset' }, bottom: 0, right: 0 }}>
            <Typography
              textAlign="center"
              fontFamily="TS Block, sans-serif"
              color="#d33379"
              fontSize={{ xs: 32, lg: 48 }}
              variant="h2">
              SHARE <br /> YOUR <br /> STORY
            </Typography>
            <Typography fontSize={{ xs: 16, lg: 20 }} textAlign="center" color="white" sx={{ ml: 3 }}>
              Unify your brands and revolutionize <br /> the way you present yourself.
            </Typography>
            <Button
              variant="contained"
              color="landingBlue"
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Lexend, sans-serif',
                fontWeight: 'bold',
                borderRadius: 20,
                mt: 5,
              }}
              onClick={() => setShowSecondProfile(!showSecondProfile)}>
              Click to swipe <br />
              between profiles
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;
