import useAuth from '../hooks/useAuth';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';

const Navigations = () => {
  const { isInitialized, uid } = useAuth();

  if (!isInitialized) return null;

  if (!uid) return <AuthRoutes />;

  return <MainRoutes />;
};

export default Navigations;
