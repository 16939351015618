import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import validator from 'validator';

import TextInput from '../../../components/TextInput';
import { checkExisted } from '../../../services/user.service';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

const EmailPassword = ({ onNext }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const cachedEmails = useRef([]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Invalid email')
      .required('Email is required')
      .test('existed', 'Email existed', async (value) => {
        if (!validator.isEmail(value.trim())) return true;
        const cached = cachedEmails.current.find((item) => item.email === value.trim());
        if (cached) return cached.valid;
        setLoading((prev) => true);
        let valid = true;
        try {
          await checkExisted({ email: value.trim() });
          cachedEmails.current.push({ email: value.trim(), valid: true });
          setLoading((prev) => false);
        } catch (err) {
          cachedEmails.current.push({ email: value.trim(), valid: false });
          valid = false;
        }
        setLoading((prev) => false);
        return valid;
      }),
    password: yup.string().min(8, 'Password must have at least 8 characters').required('Password is required'),
    confirmPassword: yup
      .string()
      .test('match', 'Password doesnt match', (value, context) => value === context.parent.password),
  });

  const onSubmit = (values) => {
    const { email, password } = values;
    onNext({ email, password });
  };

  const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      py={4}>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={{ xs: 4, md: 8 }}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography fontSize="28px" fontWeight={700} align="center">
            Create Your Connexions Account.
          </Typography>
          <Typography fontSize="20px" align="center">
            Let&apos;s begin with your login details. Please register with your email and set a password.{' '}
          </Typography>
        </Box>
        <Box width={{ xs: '80vw', sm: '400px' }} display="flex" flexDirection="column" alignItems="center">
          <TextInput
            label="Email"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur('email')}
            showError
            error={touched.email && errors.email}
          />
          <TextInput
            label="Password"
            placeholder="Password"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur('password')}
            showError
            error={touched.password && errors.password}
          />
          <TextInput
            label="Reconfirm Password"
            placeholder="Reconfirm Password"
            type="password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur('confirmPassword')}
            showError
            error={touched.confirmPassword && errors.confirmPassword}
          />
          <Button
            sx={{
              mt: 2,
              px: 6,
              borderRadius: 5,
              border: `2px solid ${theme.colors.main}`,
              color: 'black',
              bgcolor: 'white',
              textTransform: 'none',
              fontWeight: 500,
            }}
            disabled={!isValid || loading}
            onClick={handleSubmit}>
            Register
          </Button>
        </Box>
      </Box>
      <Button variant="link" onClick={() => navigate('/')} sx={{ alignSelf: 'flex-start', textTransform: 'none' }}>
        {'< Back to homepage'}
      </Button>
    </Box>
  );
};

export default EmailPassword;
