const QueryKeys = {
  Profile: 'profile',
  UserProfile: 'user-profile',
  Plans: 'plans',
  Links: 'links',
  Socials: 'socials',
  Themes: 'themes',
};

export default QueryKeys;
