import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, alpha } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import TimelineIcon from '@mui/icons-material/Timeline';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';

import { auth } from '../configs/firebase.config';

export const navigations = [
  { text: 'My Links', shortText: 'Links', path: '/links', icon: LinkIcon },
  { text: 'My Profile', shortText: 'Profile', path: '/me', icon: AccountCircleIcon },
  { text: 'Themes', path: '/theme', icon: ColorLensIcon },
  { text: 'Analytics', path: '/analytics', icon: TimelineIcon },
  { text: 'Settings', path: '/settings', icon: SettingsIcon },
];

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = (path) => path === pathname;
  const logout = () => {
    signOut(auth);
    navigate('/login');
  };

  return (
    <Box py={4} px={2} height="100%" display="flex" flexDirection="column" justifyContent="space-between" gap={1}>
      <Box display="flex" flexDirection="column" gap={1}>
        {navigations.map((item) => (
          <Box
            key={item.text}
            height="48px"
            display="flex"
            alignItems="center"
            px={2}
            gap={1}
            borderRadius={1}
            bgcolor={isActive(item.path) ? alpha(theme.colors.activeLink, 0.25) : 'transparent'}
            sx={{
              transition: 'all ease 0.3s',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: alpha(theme.colors.activeLink, isActive(item.path) ? 0.25 : 0.05),
              },
            }}
            onClick={() => navigate(item.path)}>
            <item.icon />
            <Typography fontWeight={500} fontFamily="Lexend">
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        height="48px"
        display="flex"
        alignItems="center"
        px={2}
        gap={1}
        borderRadius={1}
        sx={{
          transition: 'all ease 0.3s',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: alpha(theme.colors.activeLink, 0.05),
          },
        }}
        onClick={logout}>
        <LogoutIcon />
        <Typography fontWeight={500} fontFamily="Lexend">
          Log out
        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
