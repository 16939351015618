import { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Layout from '../../components/Layout';
import HeadTags from '../../components/HeadTags';
import DevicePreview from '../../components/DevicePreview';
import ThemeSelection from './components/ThemeSelection';
import LayoutSelection from './components/LayoutSelection';
import useAppContext from '../../hooks/useAppContext';
import { get } from '../../services/theme.service';
import { getLinks, getSocials } from '../../services/profile.service';
import QueryKeys from '../../utils/queryKeys';

const Theme = () => {
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const {
    profileState: { profile },
    createProfileModalState: { setIsCreateProfileModalOpen },
  } = useAppContext();
  const theme = useTheme();

  const { status, error, data } = useQuery({
    queryKey: [QueryKeys.Themes],
    queryFn: get,
  });
  const { data: linkData } = useQuery({
    queryKey: [QueryKeys.Links, profile?.id],
    queryFn: () => getLinks(profile?.id),
  });
  const { data: socialData } = useQuery({
    queryKey: [QueryKeys.Socials, profile?.id],
    queryFn: () => getSocials(profile?.id),
  });

  return (
    <Layout>
      <HeadTags title="Themes" description="Choose your own Connexions theme & make your brand stand out" />
      {profile ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} sx={{ height: '90vh', overflowY: 'scroll' }}>
            <Box display="flex" flexDirection="column" gap={2} px={6} py={3}>
              <LayoutSelection {...{ selectedLayout, setSelectedLayout }} />
              <ThemeSelection {...{ status, error, data, selectedTheme, setSelectedTheme }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <DevicePreview
              {...{ selectedTheme, selectedLayout, links: linkData?.data ?? [], socials: socialData?.data ?? [] }}
            />
          </Grid>
        </Grid>
      ) : (
        <Box py={4} px={{ xs: 4, md: 8, lg: 12 }} display="flex" flexDirection="column" gap={3}>
          <Typography>You got nothing to display because you do not have any profile.</Typography>
          <Typography>
            Create a new profile{' '}
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.colors.activeLink }}
              onClick={() => setIsCreateProfileModalOpen(true)}>
              here
            </span>
            .
          </Typography>
        </Box>
      )}
    </Layout>
  );
};

export default Theme;
