import api from './api';

export const updatePlan = (data) => api.put('/api/v1/me/plan', data);

export const updateProfileOrders = (data) => api.put('/api/v1/me/profiles-order', data);

export const updateAccount = (data) => api.put('/api/v1/me', data);

export const remove = () => api.delete('/api/v1/me');

export const checkExisted = (data) => api.post('/api/v1/users/existed', data);
