import { useState, useEffect } from 'react';
import { Box, Grid, Stack, Skeleton, Typography, Card, useTheme, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import DeleteSocialModal from './DeleteSocialModal';
import useAppContext from '../../../hooks/useAppContext';
import { removeSocial, updateSocialOrders } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';

const SocialList = ({ status, error, data, setIsSocialModalOpen, setEditingItem }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    profileState: { profile },
  } = useAppContext();
  const [items, setItems] = useState(data?.data || []);
  const [removingItem, setRemovingItem] = useState(null);

  useEffect(() => {
    const socials = data?.data || [];
    const paddedSocials = socials.concat(Array(6 - socials.length).fill({}));
    setItems(
      paddedSocials.map((social, index) => (social.id ? social : { id: index.toString(), isPlaceholder: true }))
    );
  }, [data?.data]);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ ids }) => updateSocialOrders(profile.id, { ids }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Socials, profile?.id] });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });
  const removeSocialMutation = useMutation({
    mutationFn: (id) => removeSocial(profile.id, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Socials, profile?.id] });
      setRemovingItem(null);
      enqueueSnackbar('Removed social link', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onRemove = async () => {
    if (!removingItem) return;
    try {
      await removeSocialMutation.mutateAsync(removingItem.id);
    } catch (err) {
      console.error(err);
    }
  };

  const onDragEnd = async (result) => {
    try {
      if (!result.destination) return;

      const clonedItems = Array.from(items);
      const [reorderedItem] = clonedItems.splice(result.source.index, 1);
      clonedItems.splice(result.destination.index, 0, reorderedItem);
      if (reorderedItem.isPlaceholder) return;

      setItems(clonedItems);
      const ids = clonedItems.map((item) => item.id);
      await mutation.mutateAsync({ ids });
    } catch (err) {
      console.error(err);
    }
  };

  if (status === 'error')
    return (
      <Box width="100%">
        <Typography align="center" color="error">
          {JSON.stringify(error)}
        </Typography>
      </Box>
    );

  if (status === 'loading')
    return (
      <Box width="100%">
        <Stack spacing={2} width="100%">
          <Skeleton variant="rounded" width="100%" height={40} />
          <Skeleton variant="rounded" width="100%" height={40} />
        </Stack>
      </Box>
    );

  return (
    <Card
      sx={{
        p: 2,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}>
      <DeleteSocialModal removingItem={removingItem} onClose={() => setRemovingItem(null)} onRemove={onRemove} />

      <Typography fontSize="20px" fontWeight={500}>
        Socials
      </Typography>
      <Typography fontSize={14}>
        Please note that you can only display up to six social media platform. Arrange your desired social media
        platform from top to bottom as the order will be reflected from left to right on your displayed screen.
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              maxHeight={624}
              overflow="scroll"
              display="flex"
              flexDirection="column"
              gap={2}
              width="100%">
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      borderRadius={2}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Grid container>
                        <Grid item xs={1}>
                          <Box
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ cursor: 'pointer' }}>
                            <SwapVertIcon sx={{ color: grey[800], fontSize: 24 }} />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sx={{
                            display: 'flex',
                            bgcolor: 'white',
                            borderRadius: 3,
                            border: `3px solid ${theme.colors.activeLink}`,
                            px: 1,
                            py: 0.5,
                            justifyContent: 'center',
                          }}
                          onClick={() => {
                            !item.isPlaceholder && setEditingItem(item);
                            setIsSocialModalOpen(true);
                          }}>
                          <Typography fontWeight={500}>{item.type ?? `Insert Link ${index + 1}`}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Box
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ cursor: 'pointer' }}>
                            <IconButton size="small" onClick={() => setRemovingItem(item)}>
                              <DeleteIcon color="error" sx={{ fontSize: 24 }} />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );
};

export default SocialList;
