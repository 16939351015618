import { Box, Button, Container, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import HeadTags from '../../components/HeadTags';

const TermsOfService = () => {
  return (
    <Box
      bgcolor="#f9adc2"
      py={10}
      sx={{
        backgroundImage: 'url(/images/landing-1-bg.webp)',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        '& *:not(h1)': { color: 'white', textAlign: 'justify' },
      }}>
      <HeadTags
        title="Terms of Service"
        description='1. Acceptance of Terms: Welcome to Connexions (the "Service"). By accessing or using this website
          ("connexions.mirl.club") or any related mobile applications, you agree to comply with and be bound
          by these Terms of Service. If you do not agree to these terms, please do not use the Service.'
      />
      <Container sx={{ display: 'flex', flexDirection: 'column', gap: 1, position: 'relative' }}>
        <Button
          sx={{ position: 'absolute', top: -40, left: 0, '&:hover': { textDecoration: 'underline' } }}
          startIcon={<NavigateBeforeIcon />}
          component="a"
          href="/">
          Back to home
        </Button>
        <Typography textAlign="center" fontSize={40} fontWeight="bold" component="h1">
          Terms of Service
        </Typography>
        <Typography fontStyle="italic">Last Updated: 18th Oct 2023</Typography>
        <Title>1. Acceptance of Terms&nbsp;</Title>
        <Typography>
          Welcome to Connexions (the &quot;Service&quot;). By accessing or using this website
          (&quot;connexions.mirl.club&quot;) or any related mobile applications, you agree to comply with and be bound
          by these Terms of Service. If you do not agree to these terms, please do not use the Service.
        </Typography>
        <Title>2. User Eligibility&nbsp;</Title>
        <Typography>
          You must be at least 18 years old or the legal age in your jurisdiction to use the Service. By using the
          Service, you represent and warrant that you meet these eligibility requirements.
        </Typography>
        <Title>3. User Accounts&nbsp;</Title>
        <Typography>
          3.1. You may be required to create an account to use certain features of the Service. You are responsible for
          maintaining the confidentiality of your account information and for all activities that occur under your
          account.
        </Typography>
        <Typography>
          3.2. You agree to provide accurate, current, and complete information during the registration process and to
          update such information to keep it accurate, current, and complete.
        </Typography>
        <Title>4. User Conduct&nbsp;</Title>
        <Typography>
          4.1. You agree not to use the Service for any illegal or unauthorized purpose. You must comply with all
          applicable laws, rules, and regulations.
        </Typography>
        <Typography>
          4.2. You may not engage in any activity that disrupts or interferes with the proper functioning of the
          Service, including transmitting viruses, spam, or any other harmful code.
        </Typography>
        <Title>5. Content&nbsp;</Title>
        <Typography>
          5.1. You are solely responsible for any content you submit or display through the Service, including but not
          limited to text, images, and videos. You represent and warrant that you have the right to submit such content.
        </Typography>
        <Typography>
          5.2. We reserve the right to remove or modify any content that violates these Terms of Service.
        </Typography>
        <Title>6. Privacy&nbsp;</Title>
        <Typography>
          Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms of
          Service. Please review our Privacy Policy to understand our practices regarding the collection and use of your
          information.
        </Typography>
        <Title>7. Termination&nbsp;</Title>
        <Typography>
          We reserve the right to suspend or terminate your account or access to the Service at our sole discretion and
          without prior notice for any violation of these Terms of Service.
        </Typography>
        <Title>8. Changes to Terms</Title>
        <Typography>
          We may revise these Terms of Service from time to time. The most current version will be posted on this
          website. Your continued use of the Service after any changes to these terms constitutes your acceptance of the
          revised terms.
        </Typography>
        <Title>9. Contact Information&nbsp;</Title>
        <Typography>
          If you have any questions about these Terms of Service, please contact us at g@mirl.club.
        </Typography>
      </Container>
    </Box>
  );
};

const Title = (props) => <Typography fontSize={24} fontWeight="bold" component="h2" sx={{ mt: 2 }} {...props} />;

export default TermsOfService;
