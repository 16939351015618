import { useId, useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import useUploadFile from '../../../hooks/useUploadFile';
import useAppContext from '../../../hooks/useAppContext';

const ProfilePhoto = ({ avatar, setAvatar, profileMutation }) => {
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { uploadFile } = useUploadFile();
  const {
    profileState: { profile },
  } = useAppContext();
  const avatarFileInputId = useId();
  const avatarInputLabelRef = useRef();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = {};
      const { storageRef, url } = await uploadFile(avatar, null, 'avatars');
      data.avatar = url;
      data.avatarStorageRef = storageRef;
      await profileMutation.mutateAsync({ ...data, handle: profile?.handle });
      setAvatar(null);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      console.error(err);
    }
    setLoading(false);
  };

  const handleAvatarInputChange = (e) => {
    setAvatar(e.target.files[0]);
    e.target.value = '';
  };

  if (!profile) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <label htmlFor={avatarFileInputId} ref={avatarInputLabelRef} style={{ display: 'none' }} />
      <input
        id={avatarFileInputId}
        style={{ display: 'none' }}
        type="file"
        multiple
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleAvatarInputChange}
      />
      <Typography fontSize="20px" fontWeight={500}>
        Profile Photo
      </Typography>
      <Box bgcolor="white" p={2} borderRadius={2} display="flex" alignItems="center" gap={2}>
        <Box
          width={80}
          height={80}
          position="relative"
          sx={{ borderRadius: '50%', overflow: 'hidden', '&:hover .MuiBox-root': { opacity: 1 } }}>
          <img
            src={avatar ? URL.createObjectURL(avatar) : profile.avatar || '/images/default-avatar.webp'}
            width="100%"
            height="100%"
            alt="avatar"
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Button variant="outlined" size="small" onClick={() => avatarInputLabelRef?.current?.click()}>
          Change Photo
        </Button>
        {avatar && (
          <Button variant="contained" size="small" onClick={onSubmit} disabled={isLoading}>
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ProfilePhoto;
