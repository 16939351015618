import { Dialog, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DeleteAccountModal = ({ open, onClose, onRemove, disabled }) => {
  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth disableEscapeKeyDown>
      <Box p={2} display="flex" flexDirection="column" gap={2} position="relative">
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>Do you want to permanently delete your account?</Typography>
          <Typography fontSize="12px" fontStyle="italic" color="error">
            All your account & profile data will be deleted. Your action cannot be undone!
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
          <Button variant="outlined" color="error" size="small" onClick={onRemove} disabled={disabled}>
            Delete
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={onClose} disabled={disabled}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteAccountModal;
