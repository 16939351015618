import { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import HeadTags from '../../../components/HeadTags';
import MobileProfileView from '../../../components/MobileProfileView';
import { clickLink, clickSocial, viewProfile } from '../../../services/analytics.service';
import { getIconSrc } from '../../../utils/constants';
import environments from '../../../utils/environments';

const { BACKEND_URL } = environments;

const Preview = ({ status, profiles, username, handle }) => {
  const [profileIndex, setProfileIndex] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const profile = useMemo(() => profiles[profileIndex], [profiles, profileIndex]);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  useEffect(() => {
    if (!profiles.length) return;

    const index = profiles.findIndex((p) => p.handle === handle.toLowerCase());
    // auto show first profile if profile handle is invalid
    setProfileIndex(Math.max(index, 0));
  }, [profiles, handle]);

  useEffect(() => {
    if (!profile) return;
    window.history.replaceState(
      null,
      `${profile.name} | Connexions`,
      `/${username}/${profile.handle}?${new URLSearchParams(window.location.search).toString()}`
    );
  }, [profile, username]);

  useEffect(() => {
    if (!profile?.id) return;
    const currentSessionId = sessionStorage.getItem(`view-profile__${profile.id}`);
    if (!currentSessionId) {
      const urlParams = new URLSearchParams(window.location.search);
      const utm_medium = urlParams.get('utm_medium');
      const utm_source = urlParams.get('utm_source');

      viewProfile({ profileId: profile.id, utm_medium, utm_source }).then((res) => {
        const { activityId } = res.data;
        setSessionId(activityId);
        sessionStorage.setItem(`view-profile__${profile.id}`, activityId);
      });
    } else {
      setSessionId(currentSessionId);
    }
  }, [profile]);

  const onClickLink = async (link) => {
    if (!sessionStorage.getItem(`click-link__${link.id}`)) {
      try {
        await clickLink({ profileId: profile.id, linkId: link.id, sessionId });
        sessionStorage.setItem(`click-link__${link.id}`, true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClickSocial = async (social) => {
    if (!sessionStorage.getItem(`click-social__${social.id}`)) {
      try {
        await clickSocial({ profileId: profile.id, socialId: social.id, sessionId });
        sessionStorage.setItem(`click-social__${social.id}`, true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onPrev = () => {
    const newIndex = profileIndex === 0 ? profiles.length - 1 : profileIndex - 1;
    setProfileIndex(newIndex);
  };
  const onNext = () => {
    const newIndex = profileIndex === profiles.length - 1 ? 0 : profileIndex + 1;
    setProfileIndex(newIndex);
  };

  if (status === 'loading' || !profile)
    return (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  if (!profiles)
    return (
      <Box
        height="100vh"
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}>
        <Typography fontSize="18px" fontWeight={600} textAlign="center" fontFamily="TS Block, sans-serif">
          404 USER NOT FOUND
        </Typography>
        <Button variant="contained" component="a" href="/">
          Back to home
        </Button>
      </Box>
    );

  if (!profiles.length)
    return (
      <Box
        height="100vh"
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}>
        <Typography fontSize={32} fontWeight={600} textAlign="center" fontFamily="TS Block, sans-serif">
          404
        </Typography>
        <Typography fontSize="18px" fontWeight={600} textAlign="center" fontFamily="TS Block, sans-serif">
          {username} HAS NO ACTIVE CONNEXIONS PROFILE
        </Typography>
        <Button variant="contained" component="a" href="/">
          Back to home
        </Button>
      </Box>
    );

  const { name, avatar, theme, links = [], socials = [], layoutId, bio, profileLabel, isVcardEnabled } = profile;
  const { solidBgColor, coverImage, gradientColors, gradientTextColor, layoutSpecific } = theme ?? {};
  const {
    headerTextColor,
    linkBgColor,
    linkIconColor,
    linkTextColor,
    socialIconsColor,
    footerTextColor,
    paginationDotsColor,
  } = layoutSpecific[layoutId];

  if (isMobile)
    return (
      <>
        <HeadTags title={`${name} (@${handle})`} description={bio} />
        <MobileProfileView
          {...{
            bio,
            profileId: profile.id,
            profileLabel,
            name,
            handle,
            avatar,
            isVcardEnabled,
            theme,
            layoutId,
            links,
            socials,
            profiles,
            profileIndex,
            setProfileIndex,
            onClickLink,
            onClickSocial,
          }}
        />
      </>
    );

  return (
    <Box
      height="100vh"
      position="relative"
      sx={{
        backgroundImage: `url('${coverImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className="user-profile">
      <HeadTags title={`${name} (@${handle})`} description={bio} />
      <Box
        bgcolor={solidBgColor}
        position="absolute"
        sx={{
          WebkitMaskImage: `url(/images/wave.svg)`,
          maskImage: `url(/images/wave.svg)`,
          maskRepeat: 'no-repeat',
          maskSize: 'cover',
          maskPosition: { xs: '0% 30vh', sm: '0% 20vh', md: '25% 25%' },
          width: '100vw',
          height: '100vh',
        }}
      />
      <Box
        position="absolute"
        borderRadius={3}
        width={{ xs: '80%', sm: '360px' }}
        border={`2px solid ${paginationDotsColor}`}
        height={{ xs: '82%', md: '87%', xl: '75%' }}
        bgcolor={solidBgColor}
        sx={{ opacity: 0.7 }}
        mb={{ xs: '12%', md: '5%' }}
      />
      <Box
        borderRadius={3}
        py={{ xs: 2, md: 3 }}
        width={{ xs: '80%', sm: '360px' }}
        height={{ xs: '82%', md: '87%', xl: '75%' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={1}
        mb={{ xs: '12%', md: '5%' }}
        position="absolute">
        <Box width="100%" px={4} display="flex" flexDirection="column" alignItems="center" gap={1}>
          {isVcardEnabled && (
            <Button
              variant="contained"
              color="neutral"
              size="small"
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                zIndex: 3,
                fontFamily: 'Conthrax, sans-serif',
                fontSize: 10,
                textAlign: 'left',
                lineHeight: 1.25,
                textTransform: 'none',
                borderRadius: '0 10px 0 20px',
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }}
              startIcon={<PersonAddIcon />}
              component="a"
              href={`${BACKEND_URL}/api/v1/profiles/vcard/${profile.id}`}
              target="_blank"
              rel="noopener noreferrer">
              Save <br /> Contact
            </Button>
          )}
          <Box flexShrink={0} width="70px" mx="auto" borderRadius="50%" overflow="hidden" sx={{ aspectRatio: '1/1' }}>
            <img
              src={avatar || '/images/default-avatar.webp'}
              alt="avatar"
              style={{
                width: '100%',
                aspectRatio: '1/1',
                objectFit: 'cover',
                objectPosition: 'center',
                display: 'block',
              }}
            />
          </Box>
          <Box>
            <Typography
              fontSize="18px"
              fontWeight={600}
              textAlign="center"
              color={headerTextColor}
              lineHeight={1}
              fontFamily="TS Block, sans-serif">
              {name}
            </Typography>
            <Typography
              fontSize={11}
              fontWeight={600}
              textAlign="center"
              color={headerTextColor}
              fontFamily="TS Block, sans-serif">
              @{profile.handle}
            </Typography>
          </Box>
          <Typography
            align="center"
            color={headerTextColor}
            sx={{ whiteSpace: 'pre-line', height: '4em' }}
            lineHeight={1}
            fontFamily="DarkerGrotesque, sans-serif">
            {bio}
          </Typography>
        </Box>
        <Box
          px={2}
          position="absolute"
          border="1px solid white"
          sx={{
            transform: 'rotate(90deg)',
            backgroundImage: `linear-gradient(to right, ${gradientColors.join(', ')})`,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflow: 'hidden',
            width: 90,
            top: '25%',
            right: -55,
          }}>
          <Typography
            textAlign="center"
            color={gradientTextColor}
            fontFamily="Himagsikan"
            fontSize={12}
            sx={{ userSelect: 'none' }}>
            {profileLabel}
          </Typography>
        </Box>
        <Box
          width="100%"
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
          overflow="hidden"
          borderTop={`2px solid ${paginationDotsColor}`}
          pt={2}
          px={4}>
          <Typography fontFamily="Conthrax, sans-serif" fontSize={12} textAlign="center" color={headerTextColor}>
            FEATURED
          </Typography>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            height={{ xs: 5 * 40, lg: 5 * 46 }}
            sx={{ overflowX: 'hidden', overflowY: 'scroll' }}
            pr="3px"
            pb="3px"
            ml="3px">
            {links.map((item) => (
              <Box
                key={`${item.title}-${item.url}`}
                width="100%"
                px={2}
                py={0.5}
                bgcolor={linkBgColor}
                borderRadius={10}
                display="flex"
                alignItems="center"
                gap={2}
                boxShadow={`3px 3px 1px 0px ${linkTextColor}`}
                component="a"
                href={item.url}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => onClickLink(item)}
                sx={{ textDecoration: 'none', transition: 'all 0.3s ease', '&:hover': { opacity: 0.8 } }}>
                <Box width={{ xs: '24px', lg: '30px' }} sx={{ aspectRatio: '1/1' }}>
                  <img
                    src={item.icon || getIconSrc(`${linkIconColor}/${item.type}_Icon.png`)}
                    alt="icon"
                    style={{
                      width: '100%',
                      aspectRatio: '1/1',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      display: 'block',
                    }}
                  />
                </Box>
                <Typography fontSize={12} fontWeight={600} color={linkTextColor} fontFamily="Conthrax, sans-serif">
                  {item.title}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" gap={1} mt="auto">
            <Typography fontFamily="Conthrax, sans-serif" fontSize={12} textAlign="center" color={headerTextColor}>
              MY SOCIALS
            </Typography>
            <Box display="flex" gap={{ xs: 0, lg: 0.5 }}>
              {socials.map((social) => (
                <a
                  key={social.id}
                  href={social.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => onClickSocial(social)}>
                  <img
                    src={getIconSrc(`${socialIconsColor}/${social.type}_Icon.png`)}
                    width={40}
                    fill={socialIconsColor}
                    alt={social.url}
                    style={{ borderRadius: 12 }}
                  />
                </a>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        pb={1}
        height="20%"
        position="absolute"
        sx={{ bottom: 0 }}>
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton onClick={onPrev}>
            <ArrowBackIcon htmlColor={paginationDotsColor} />
          </IconButton>
          {profiles.map((_, index) => (
            <Box
              key={index}
              width={8}
              height={8}
              borderRadius="50%"
              bgcolor={paginationDotsColor}
              sx={{ opacity: index === profileIndex ? 1 : 0.5 }}
            />
          ))}
          <IconButton onClick={onNext}>
            <ArrowForwardIcon htmlColor={paginationDotsColor} />
          </IconButton>
        </Box>
        <Typography
          fontSize={{ md: 10, lg: 12 }}
          fontWeight={500}
          color={footerTextColor}
          fontFamily="Himagsikan, sans-serif">
          CONNEXIONS
        </Typography>
      </Box>
    </Box>
  );
};

export default Preview;
