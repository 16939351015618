import { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import Layout from '../../components/Layout';
import HeadTags from '../../components/HeadTags';
import ProfilePhoto from './components/ProfilePhoto';
import ProfileDetails from './components/ProfileDetails';
import RemoveProfile from './components/RemoveProfile';
import ProfileBio from './components/ProfileBio';
import ContactDetails from './components/ContactDetails';
import DevicePreview from '../../components/DevicePreview';
import useAppContext from '../../hooks/useAppContext';
import { getLinks, getSocials, updateProfile } from '../../services/profile.service';
import QueryKeys from '../../utils/queryKeys';
import { usernameRegex } from '../../utils/strings';

const validationSchema = yup.object({
  profileLabel: yup.string().required('Profile label is required'),
  name: yup.string().required('Display name is required'),
  handle: yup
    .string()
    .required('Handle is required')
    .min(6, 'Handles must be at least 6 characters long')
    .matches(usernameRegex, 'Handles can only have letters, numbers, dots (.) and underscores (_)'),
});

const Profile = () => {
  const [avatar, setAvatar] = useState(null);
  const [bio, setBio] = useState('');
  const [isVcardEnabled, setIsVcardEnabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile },
    createProfileModalState: { setIsCreateProfileModalOpen },
  } = useAppContext();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const profileMutation = useMutation({
    mutationFn: (data) => updateProfile(profile.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const { data: linkData } = useQuery({
    queryKey: [QueryKeys.Links, profile?.id],
    queryFn: () => getLinks(profile?.id),
  });
  const { data: socialData } = useQuery({
    queryKey: [QueryKeys.Socials, profile?.id],
    queryFn: () => getSocials(profile?.id),
  });
  const { profileLabel, name, handle } = profile || {};
  const initialValues = {
    profileLabel: profileLabel || 'PERSONAL',
    name: name || '',
    handle: handle || '',
  };

  const onSubmit = async (values) => {
    try {
      await profileMutation.mutateAsync(values);
      enqueueSnackbar('Update profile successfully', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const { values, touched, errors, isValid, setFieldValue, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Layout>
      <HeadTags title="My Profile" description="Customize the displayed information in your Connexions profile" />
      {profile ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} sx={{ height: '90vh', overflowY: 'scroll' }}>
            <Box py={4} px={{ xs: 4, md: 8, lg: 12 }} display="flex" flexDirection="column" gap={3}>
              <ProfilePhoto {...{ avatar, setAvatar, profileMutation }} />
              <ProfileDetails
                {...{ values, touched, errors, isValid, setFieldValue, handleBlur, handleSubmit, profileMutation }}
              />
              <ProfileBio {...{ bio, setBio }} />
              <ContactDetails {...{ isVcardEnabled, setIsVcardEnabled, profileMutation }} />
              <RemoveProfile />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <DevicePreview
              {...{
                currentBio: bio,
                links: linkData?.data ?? [],
                socials: socialData?.data ?? [],
                currentProfileLabel: values.profileLabel,
                currentName: values.name,
                currentHandle: values.handle,
                currentAvatar: avatar,
                currentIsVcardEnabled: isVcardEnabled,
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Box py={4} px={{ xs: 4, md: 8, lg: 12 }} display="flex" flexDirection="column" gap={3}>
          <Typography>You got nothing to display because you do not have any profile.</Typography>
          <Typography>
            Create a new profile{' '}
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.colors.activeLink }}
              onClick={() => setIsCreateProfileModalOpen(true)}>
              here
            </span>
            .
          </Typography>
        </Box>
      )}
    </Layout>
  );
};

export default Profile;
