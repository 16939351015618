import { useMemo } from 'react';
import { Box, Typography, TextField, IconButton, useTheme, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';

import useAppContext from '../../../hooks/useAppContext';
import environments from '../../../utils/environments';

const { SITE_URL } = environments;

const ProfileDetails = ({
  values,
  touched,
  errors,
  isValid,
  setFieldValue,
  handleBlur,
  handleSubmit,
  profileMutation,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    profileState: { user, profile },
  } = useAppContext();

  const profileLink = `${SITE_URL}/${user?.username}/${profile?.handle}`;

  const isSomethingChanged = useMemo(
    () =>
      values.name !== profile?.name ||
      values.handle !== profile?.handle ||
      values.profileLabel !== profile?.profileLabel,
    [values, profile?.name, profile?.handle, profile?.profileLabel]
  );

  const onCopyProfileLink = () => {
    navigator.clipboard.writeText(profileLink);
    enqueueSnackbar(`Profile link copied`, {
      variant: 'success',
    });
  };

  if (!profile) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontSize="20px" fontWeight={500}>
        Profile Details
      </Typography>
      <Box bgcolor="white" p={2} borderRadius={2} display="flex" flexDirection="column">
        <TextField
          variant="standard"
          label="Display Name"
          name="name"
          value={values.name}
          onChange={(e) => setFieldValue('name', e.target.value.trim())}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          helperText={(touched.name && errors.name) || ' '}
          sx={{ pb: touched.name && errors.name ? 0.15 : 0 }}
        />
        <TextField
          variant="standard"
          label="URL Tag Label"
          name="handle"
          value={values.handle}
          onChange={(e) => setFieldValue('handle', e.target.value.trim())}
          onBlur={handleBlur}
          error={touched.handle && errors.handle}
          helperText={(touched.handle && errors.handle) || ' '}
          sx={{ pb: touched.handle && errors.handle ? 0.15 : 0 }}
        />
        <TextField
          variant="standard"
          label="Connexions Profile Link"
          value={profileLink}
          disabled
          helperText={' '}
          InputProps={{
            endAdornment: (
              <>
                <IconButton onClick={onCopyProfileLink} title="Copy">
                  <ContentCopyIcon fontSize="small" htmlColor={theme.colors.activeLink} />
                </IconButton>
                <IconButton
                  component="a"
                  href={profileLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="View profile">
                  <LaunchIcon fontSize="small" htmlColor={theme.palette.secondary.main} />
                </IconButton>
              </>
            ),
          }}
        />
        <TextField
          variant="standard"
          label="Display Tab"
          name="profileLabel"
          value={values.profileLabel}
          onChange={(e) => setFieldValue('profileLabel', e.target.value.trim())}
          onBlur={handleBlur}
          error={touched.profileLabel && errors.profileLabel}
          helperText={(touched.profileLabel && errors.profileLabel) || ' '}
          sx={{ pb: touched.profileLabel && errors.profileLabel ? 0.15 : 0 }}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          color="neutral"
          onClick={handleSubmit}
          disabled={profileMutation.isLoading || !isValid || !isSomethingChanged}>
          Update Profile
        </Button>
      </Box>
    </Box>
  );
};

export default ProfileDetails;
