import { useEffect, useRef, useState } from 'react';
import { Dialog, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import IconList from './IconList';
import CustomForm from './CustomForm';
import EmailForm from './EmailForm';

const CreateLinkModal = ({ open, onClose }) => {
  const [customUrl, setCustomUrl] = useState('');
  const [type, setType] = useState(null);

  const reset = () => {
    setType(null);
    setCustomUrl('');
  };

  const timeout = useRef();
  useEffect(() => {
    if (!open) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(reset, 500);
    }
  }, [open]);

  const render = () => {
    if (!type) return <IconList customUrl={customUrl} setCustomUrl={setCustomUrl} setType={setType} />;
    if (type === 'Email') return <EmailForm onClose={onClose} />;
    return <CustomForm type={type} initUrl={customUrl} onClose={onClose} />;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
      <Box p={2} display="flex" flexDirection="column" gap={2} position="relative">
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        {render()}
      </Box>
    </Dialog>
  );
};

export default CreateLinkModal;
