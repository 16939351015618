import { Box, Grid, Typography, useTheme } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import useAppContext from '../../../hooks/useAppContext';

export default function ThemeItem({ theme, isSelected, setSelectedTheme }) {
  const {
    profileState: { user },
  } = useAppContext();
  const muiTheme = useTheme();

  return (
    <Grid item xs={6} md={3}>
      <Box
        borderRadius={1}
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            bgcolor: 'rgba(0,0,0,0.04)',
          },
        }}
        onClick={() => setSelectedTheme(theme)}>
        <Box
          position="relative"
          width="100%"
          borderRadius={1}
          border={`3px solid ${isSelected ? muiTheme.colors.activeLink : 'transparent'}`}
          p={1}
          pb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ aspectRatio: '1/1.4' }}
          gap={{ xs: 0.5, md: 1 }}>
          {theme.planId === 'premium' && (
            <Box
              position="absolute"
              top={6}
              left="50%"
              borderRadius={1.5}
              sx={{ transform: 'translateX(-50%)' }}
              bgcolor="black"
              px={1}
              py={0.5}
              gap={0.5}
              display="flex"
              alignItems="center">
              <Typography fontWeight="500" fontSize="8px" color="white" sx={{ userSelect: 'none' }}>
                Premium
              </Typography>
              {user?.planId !== 'premium' && <LockOutlinedIcon htmlColor="white" sx={{ fontSize: 10 }} />}
            </Box>
          )}
          <Typography fontWeight="700" fontSize={{ xs: 12, md: 16 }} textAlign="center">
            {theme.name}
          </Typography>
          <Box
            borderRadius={4}
            sx={{ backgroundImage: `linear-gradient(to bottom, ${theme.gradientColors.join(', ')})` }}
            width="100%"
            height={{ xs: 14, md: 18, lg: 25 }}
          />
          <Box borderRadius={4} bgcolor={theme.solidBgColor} width="100%" height={{ xs: 14, md: 18, lg: 25 }} />
          <Box
            borderRadius={4}
            sx={{ backgroundImage: `linear-gradient(to right, ${theme.gradientColors.join(', ')})` }}
            width="100%"
            height={{ xs: 14, md: 18, lg: 25 }}
          />
          <Box
            borderRadius={4}
            border={`1px solid ${theme.layoutSpecific.dynamicWaves.linkTextColor}`}
            bgcolor={theme.layoutSpecific.dynamicWaves.linkBgColor}
            width="100%"
            height={{ xs: 14, md: 18, lg: 25 }}
          />
          <Box
            borderRadius={4}
            bgcolor={theme.layoutSpecific.dynamicWaves.linkTextColor}
            width="100%"
            height={{ xs: 14, md: 18, lg: 25 }}
          />
          <Box
            borderRadius={4}
            bgcolor={theme.layoutSpecific.dynamicWaves.paginationDotsColor}
            width="100%"
            height={{ xs: 14, md: 18, lg: 25 }}
          />
        </Box>
      </Box>
    </Grid>
  );
}
