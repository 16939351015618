import { Dialog, Box, Grid, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

import usePlans from '../../../hooks/usePlans';

const PlanModal = ({ open, onClose, currentPlanId, onSelect }) => {
  const { data } = usePlans();

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth disableEscapeKeyDown>
      <Box p={2} display="flex" flexDirection="column" gap={2} position="relative">
        <Box position="absolute" right="8px" top="8px" sx={{ cursor: 'pointer' }} onClick={onClose}>
          <CloseIcon />
        </Box>
        <Typography fontSize="20px" fontWeight={600}>
          Plans
        </Typography>
        <Box>
          <Grid container spacing={2}>
            {(data || []).map((item) => (
              <Grid key={item.id} item xs={12}>
                <Box
                  p={2}
                  borderRadius={2}
                  border={`1px solid ${grey[300]}`}
                  display="flex"
                  flexDirection="column"
                  gap={1}>
                  <Typography fontSize="18px" fontWeight={500} align="center">
                    {item.name}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      p: 0.5,
                      textTransform: 'none',
                    }}
                    disabled={item.id === currentPlanId}
                    onClick={() => onSelect(item.id)}>
                    {item.id === currentPlanId ? 'Your plan' : 'Select'}
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PlanModal;
