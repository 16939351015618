import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthLayout from '../../components/AuthLayout';
import HeadTags from '../../components/HeadTags';
import TextInput from '../../components/TextInput';
import { resetPassword } from '../../services/auth.service';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const theme = useTheme();
  const { resetPasswordRequestId } = useParams();

  const validationSchema = yup.object({
    password: yup.string().min(8, 'Password must have at least 8 characters').required('Password is required'),
    confirmPassword: yup
      .string()
      .test('match', 'Password doesnt match', (value, context) => value === context.parent.password),
  });

  const onSubmit = async ({ password }) => {
    setLoading(true);
    try {
      await resetPassword(resetPasswordRequestId, password);
      setIsSuccess(true);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setLoading(false);
  };

  const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  return (
    <AuthLayout>
      <HeadTags title="Reset Password" description="Enter new password to reset your password" />
      {isSuccess ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          py={10}>
          <Box
            width={{ xs: '80vw', md: '800px' }}
            maxWidth="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}>
            <Typography fontSize="24px" fontWeight={500} align="center">
              Password Changed!
            </Typography>
            <img src="/images/password-changed.svg" alt="" width={400} />
            <Button
              sx={{
                mt: 2,
                px: 6,
                borderRadius: 5,
                border: `2px solid ${theme.colors.main}`,
                color: 'black',
                bgcolor: 'white',
                textTransform: 'none',
                fontWeight: 500,
              }}
              component="a"
              href="/login">
              Login Now
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          py={4}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
              <Typography fontSize="24px" fontWeight={500} align="center">
                Reset Password
              </Typography>
            </Box>
            <Box width={{ xs: '80vw', sm: '400px' }} display="flex" flexDirection="column" alignItems="center">
              <TextInput
                label="Password"
                placeholder="Password"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                showError
                error={touched.password && errors.password}
              />
              <TextInput
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                showError
                error={touched.confirmPassword && errors.confirmPassword}
              />
              <Button
                sx={{
                  mt: 2,
                  px: 6,
                  borderRadius: 5,
                  border: `2px solid ${theme.colors.main}`,
                  color: 'black',
                  bgcolor: 'white',
                  textTransform: 'none',
                  fontWeight: 500,
                }}
                disabled={!isValid || loading}
                onClick={handleSubmit}>
                Reset Password
              </Button>
            </Box>
          </Box>
          <Button
            variant="link"
            onClick={() => navigate('/login')}
            sx={{ alignSelf: 'flex-start', textTransform: 'none' }}>
            {'< Back to login'}
          </Button>
        </Box>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
