import { Dialog, Box, Typography, Button, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DeleteProfileModal = ({ open, onClose, onRemove, disabled }) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth disableEscapeKeyDown>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        position="relative"
        border="1px solid black"
        bgcolor={theme.colors.bgcolor}>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box display="flex" flexDirection="column">
          <Typography fontSize={20} fontWeight={500} textAlign="center">
            Are you sure?
          </Typography>
          <Typography fontWeight={500} textAlign="center">
            Once deleted, the profile cannot be retrieved.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          <Button
            variant="outlined"
            color="neutral"
            size="small"
            sx={{ fontSize: '12px', textTransform: 'none', p: 0 }}
            onClick={onClose}
            disabled={disabled}>
            Go back
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{ fontSize: '12px', textTransform: 'none', p: 0 }}
            onClick={onRemove}
            disabled={disabled}>
            Delete
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteProfileModal;
