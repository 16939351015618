import { useState } from 'react';
import { Box, Button, Container, InputAdornment, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { submitEmail } from '../../../services/subscriber.service';

const Footer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitEmail = async () => {
    setIsLoading(true);
    try {
      await submitEmail({ email });
      enqueueSnackbar('Email submitted! We will let you know when to revisit', { variant: 'success' });
      setEmail('');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'warning' });
    }
    setIsLoading(false);
  };

  return (
    <Box
      id="faqs"
      position="relative"
      minHeight="100vh"
      bgcolor="#f9adc2"
      sx={{
        backgroundImage: 'url(/images/landing-1-bg.webp)',
        backgroundSize: 'cover',
      }}>
      <Box
        width="100%"
        height="100%"
        position="absolute"
        sx={{
          backgroundImage: 'url(/images/landing-4-curve.svg)',
          backgroundSize: { xs: 'auto 60%', md: 'contain' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
        display="flex"
        flexDirection="column"
        pb={2}>
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            py: '5%',
            px: { xs: 3, md: 5 },
          }}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems={{ xs: 'center', md: 'flex-start' }}
            gap={{ xs: 2, md: 3 }}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                textAlign={{ xs: 'center', md: 'left' }}
                fontSize={{ xs: 40, lg: 48 }}
                fontFamily="TS Block, sans-serif"
                variant="h1">
                FUTURE NFC <br /> PRODUCTS
              </Typography>
              <Typography
                color="#ed6d60"
                textAlign={{ xs: 'center', md: 'left' }}
                fontWeight="bold"
                fontFamily="Lexend, sans-serif"
                fontSize={{ xs: 24, lg: 32, xl: 40 }}>
                Go beyond the digital space
              </Typography>
            </Box>
            <Typography textAlign={{ xs: 'center', md: 'left' }} fontWeight={700} fontSize={{ xs: 16, lg: 20, xl: 25 }}>
              A wearable extension to your personal brand.
            </Typography>
          </Box>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            alignItems={{ xs: 'center', md: 'flex-end' }}
            gap={2}>
            <Typography
              textAlign={{ xs: 'center', md: 'right' }}
              fontSize={{ xs: 40, lg: 48 }}
              fontFamily="TS Block, sans-serif"
              variant="h1">
              COMING SOON
            </Typography>
            <Typography
              textAlign={{ xs: 'center', md: 'right' }}
              fontWeight={700}
              fontSize={{ xs: 16, lg: 20, xl: 25 }}>
              We are currently working hard building the product.
            </Typography>
            <TextField
              size="small"
              name="email"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                fontFamily: 'Lexend',
                fontSize: { xs: 16, lg: 20, xl: 25 },
                width: { xs: 300, md: 400 },
                '& .MuiInputBase-root': {
                  borderRadius: 100,
                  backgroundColor: 'white',
                  pr: 0,
                  '& button': { lineHeight: 1.9 },
                },
                '& fieldset': { borderRadius: 100 },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="neutral"
                      sx={{ borderRadius: 100 }}
                      onClick={onSubmitEmail}
                      disabled={isLoading || !email}
                      value={email}>
                      Notify me
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Container>
        <Typography textAlign="center" fontWeight="bold" fontFamily="Himagsikan">
          Connexions - powered by Made In Real Life
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
