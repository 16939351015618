import { Routes, Route } from 'react-router-dom';

// dont need HomeRoute level bc its quite simple app
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';
import Home from '../pages/Home';
import Links from '../pages/Links';
import Theme from '../pages/Theme';
import Analytics from '../pages/Analytics';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import Verify from '../pages/Verify';
import UserProfile from '../pages/UserProfile';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/links" element={<Links />} />
      <Route path="/me" element={<Profile />} />
      <Route path="/theme" element={<Theme />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/verify/:activateRequestId" element={<Verify />} />
      <Route path="/" element={<Home />} />
      <Route path="/:username/:handle" element={<UserProfile />} />
      <Route path="/:username" element={<UserProfile />} />
    </Routes>
  );
};

export default MainRoutes;
