import { Box, Typography, useTheme } from '@mui/material';

const AuthLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Box height="100vh" overflow="auto" bgcolor={theme.colors.bgcolor}>
      <Box height="85px" px={2} display="flex" alignItems="center">
        <Typography fontFamily="Himagsikan, sans-serif !important" fontSize={{ xs: 24, md: 32 }}>
          Connexions
        </Typography>
      </Box>
      <Box
        height="calc(100vh - 85px)"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={{ '& .MuiTypography-root, & .MuiButton-root': { fontFamily: 'Lexend, sans-serif' } }}>
        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;
