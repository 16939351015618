import { Dialog, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

import useAppContext from '../../../hooks/useAppContext';
import { requestResetPassword } from '../../../services/auth.service';

const ResetPasswordModal = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { user },
  } = useAppContext();

  const onChangePasswordPress = async () => {
    const email = user?.email;
    if (!email) return;
    onClose();
    try {
      await requestResetPassword({ email });
      enqueueSnackbar(`Reset password link sent to ${email}!`, { variant: 'success' });
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth disableEscapeKeyDown>
      <Box p={2} display="flex" flexDirection="column" gap={2} position="relative">
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>Update your password?</Typography>
          <Typography fontSize="12px" fontStyle="italic">
            We will send a link to {user?.email} for you to reset your password.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
          <Button variant="outlined" color="secondary" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={onChangePasswordPress}>
            Email me
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ResetPasswordModal;
