import { useQuery } from '@tanstack/react-query';

import { get } from '../services/plan.service';
import QueryKeys from '../utils/queryKeys';

const usePlans = () => {
  const { data } = useQuery({
    queryKey: [QueryKeys.Plans],
    queryFn: get,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 0,
  });

  return data || {};
};

export default usePlans;
