import { useState } from 'react';
import { Box, Typography, TextField, Button, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import InputButton from '../../../components/InputButton';
import useProfile from '../../../hooks/useProfile';
import { verifyUser } from '../../../services/auth.service';
import { updateAccount } from '../../../services/user.service';
import QueryKeys from '../../../utils/queryKeys';
import { usernameRegex } from '../../../utils/strings';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  username: yup
    .string()
    .required('Username is required')
    .min(6, 'Username must be at least 6 characters long')
    .matches(usernameRegex, 'Usernames can only have letters, numbers, dots (.) and underscores (_)'),
  email: yup.string().email('Invalid email').required('Email is required'),
});

const AccountDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useProfile();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Updated account details', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'warning' });
    },
  });

  const [loading, setLoading] = useState(false);

  const verifyAccount = async () => {
    if (!user || !user.id) return;
    setLoading(true);

    try {
      await verifyUser(user?.id);
      enqueueSnackbar('Verify email is sent', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    setLoading(false);
  };

  const initialValues = {
    name: user?.name || '',
    username: user?.username || '',
    email: user?.email || '',
  };

  const onSubmit = async (values) => {
    try {
      await mutation.mutateAsync(values);
    } catch (err) {
      console.error(err);
    }
  };

  const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  if (!user) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize="20px" fontWeight={500}>
        My Account Details
      </Typography>
      <Box bgcolor="white" p={2} borderRadius={2} display="flex" flexDirection="column" gap={2}>
        <TextField
          variant="standard"
          label="Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          helperText={errors.name || ''}
          disabled={mutation.isLoading}
        />
        <TextField
          variant="standard"
          label="Account Username"
          name="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.username && errors.username}
          helperText={errors.username || ''}
          disabled={mutation.isLoading}
        />
        <TextField
          variant="standard"
          label="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          helperText={errors.email || ''}
          disabled={mutation.isLoading}
          InputProps={{
            endAdornment:
              user?.status === 'unverified' ? (
                <Box pb={1}>
                  <InputButton
                    text="Verify"
                    onClick={verifyAccount}
                    disabled={loading}
                    bgcolor={theme.palette.primary.main}
                  />
                </Box>
              ) : null,
          }}
        />
      </Box>
      <Box>
        <Button variant="contained" color="neutral" disabled={!isValid || mutation.isLoading} onClick={handleSubmit}>
          Save details
        </Button>
      </Box>
    </Box>
  );
};

export default AccountDetails;
