import { useEffect, useState } from 'react';
import { Box, Button, Card, Menu, Typography, useTheme } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';

import Layout from '../../components/Layout';
import HeadTags from '../../components/HeadTags';
import useAppContext from '../../hooks/useAppContext';
import { getAnalytics } from '../../services/analytics.service';

const Analytics = () => {
  const [analytics, setAnalytics] = useState({});
  const [previewDateRange, setPreviewDateRange] = useState({ key: 'selection', ...getDefaultDateRange() });
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  const [dateSelectorAnchorEl, setDateSelectorAnchorEl] = useState(null);
  const isDateSelectorOpen = Boolean(dateSelectorAnchorEl);
  const {
    profileState: { profile },
    createProfileModalState: { setIsCreateProfileModalOpen },
  } = useAppContext();
  const theme = useTheme();

  // TODO: future feature: display viewsSource in Analytics
  const { viewsCount, clicksCount, links, socials } = analytics;

  useEffect(() => {
    if (!profile?.id) return;
    getAnalytics({
      profileId: profile?.id,
      start: dateRange.startDate.toString(),
      end: dateRange.endDate.toString(),
    }).then((res) => {
      setAnalytics(res.data);
    });
  }, [profile?.id, dateRange]);

  const openDateSelector = (event) => {
    setPreviewDateRange({ key: 'selection', ...dateRange });
    setDateSelectorAnchorEl(event.currentTarget);
  };
  const closeDateSelector = () => {
    setDateSelectorAnchorEl(null);
  };

  const saveDate = () => {
    setDateRange({ key: 'selection', ...previewDateRange });
    closeDateSelector();
  };

  if (!profile)
    return (
      <Layout>
        <Box py={4} px={{ xs: 4, md: 8, lg: 12 }} display="flex" flexDirection="column" gap={3}>
          <Typography>You got nothing to display because you do not have any profile.</Typography>
          <Typography>
            Create a new profile
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline', color: theme.colors.activeLink }}
              onClick={() => setIsCreateProfileModalOpen(true)}>
              here
            </span>
            .
          </Typography>
        </Box>
      </Layout>
    );

  return (
    <Layout>
      <HeadTags title="Analytics" description="View how your Connexions brand is performing" />
      <Box display="flex" flexDirection="column" gap={3} px={{ xs: 6, md: 10, lg: 14 }} py={3}>
        <Typography fontSize={20} fontWeight={500}>
          Profile Analytics
        </Typography>
        <Card
          sx={{
            p: 2,
            borderRadius: 2,
            display: 'flex',
            gap: 1,
            justifyContent: 'space-around',
          }}>
          <Box display="flex" alignItems="center" gap={1}>
            <Box bgcolor={theme.palette.primary.main} width={12} height={12} sx={{ borderRadius: 6 }} />
            <Typography>Views</Typography>
            <Typography fontSize={28} fontWeight={600} sx={{ ml: 1 }}>
              {viewsCount?.toLocaleString() ?? '-'}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Box bgcolor={theme.palette.secondary.main} width={12} height={12} sx={{ borderRadius: 6 }} />
            <Typography>Clicks</Typography>
            <Typography fontSize={28} fontWeight={600} sx={{ ml: 1 }}>
              {clicksCount?.toLocaleString() ?? '-'}
            </Typography>
          </Box>
        </Card>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>Custom dates:</Typography>
          <Card sx={{ px: 2, py: 1, borderRadius: 2 }}>
            <Button startIcon={<DateRangeIcon />} onClick={openDateSelector} color="secondary">
              {format(dateRange.startDate, 'do MMM yyyy')} - {format(dateRange.endDate, 'do MMM yyyy')}
            </Button>
          </Card>
        </Box>
        <Menu
          anchorEl={dateSelectorAnchorEl}
          open={isDateSelectorOpen}
          onClose={closeDateSelector}
          MenuListProps={{ sx: { display: 'flex', flexDirection: 'column' } }}>
          <DateRange
            showSelectionPreview={true}
            onChange={(item) => setPreviewDateRange(item.selection)}
            moveRangeOnFirstSelection={false}
            maxDate={new Date()}
            rangeColors={[theme.palette.secondary.main]}
            ranges={[previewDateRange]}
          />
          <Box display="flex" justifyContent="space-between" px={1}>
            <Button onClick={closeDateSelector}>Cancel</Button>
            <Button variant="contained" color="secondary" onClick={saveDate}>
              Save
            </Button>
          </Box>
        </Menu>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>Top Performing Links:</Typography>
          <Card sx={{ p: 2, borderRadius: 2 }}>
            {links?.length ? (
              <>
                <Box display="flex" justifyContent="flex-end">
                  <Typography sx={{ width: 100 }} textAlign="center">
                    Clicks
                  </Typography>
                </Box>
                {links.map((item) => (
                  <Box
                    key={item.id}
                    pl={1}
                    py={0.5}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ borderBottom: '1px solid #c3c3c3' }}>
                    <Typography>{item.title}</Typography>
                    <Typography sx={{ width: 100 }} textAlign="center">
                      {item.clicksCount.toLocaleString()}
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                <Typography textAlign="center">No links to display.</Typography>
                <Button variant="contained" color="secondary" component="a" href="/links">
                  Add
                </Button>
              </Box>
            )}
          </Card>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>Top Performing Socials:</Typography>
          <Card sx={{ p: 2, borderRadius: 2 }}>
            {socials?.length ? (
              <>
                <Box display="flex" justifyContent="flex-end">
                  <Typography sx={{ width: 100 }} textAlign="center">
                    Clicks
                  </Typography>
                </Box>
                {socials.map((item) => (
                  <Box
                    key={item.id}
                    pl={1}
                    py={0.5}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ borderBottom: '1px solid #c3c3c3' }}>
                    <Typography>{item.type}</Typography>
                    <Typography sx={{ width: 100 }} textAlign="center">
                      {item.clicksCount.toLocaleString()}
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                <Typography textAlign="center">No socials to display.</Typography>
                <Button variant="contained" color="secondary" component="a" href="/links">
                  Add
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Box>
    </Layout>
  );
};

const getDefaultDateRange = () => {
  const today = new Date();
  const aWeekAgo = new Date();
  aWeekAgo.setDate(today.getDate() - 7);

  return { startDate: aWeekAgo, endDate: today };
};

export default Analytics;
