import {
  Box,
  Divider,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import CopyUserProfileLink from './CopyUserProfileLink';
import useAppContext from '../../../hooks/useAppContext';
import environments from '../../../utils/environments';

const { SITE_URL } = environments;

const ShareLinkDefaultMenu = ({ handleClose, setCurrentPage }) => {
  const {
    profileState: { user, profile },
  } = useAppContext();

  const userProfileLink = `${SITE_URL}/${user?.username}/${profile?.handle}`;

  return (
    <>
      <Typography
        fontWeight="bold"
        fontSize="18px"
        sx={{ padding: '6px 16px' }}
      >
        Share Your Connexions Link
      </Typography>
      <Divider />
      <MenuItem onClick={() => setCurrentPage('socials')} divider>
        <ListItemText>Share on Socials</ListItemText>
        <NavigateNextIcon />
      </MenuItem>
      <MenuItem onClick={() => setCurrentPage('qr')} divider>
        <ListItemText>Get my Connexion QR code</ListItemText>
        <NavigateNextIcon />
      </MenuItem>
      <MenuItem
        component="a"
        href={userProfileLink}
        target="_blank"
        onClick={handleClose}
        divider
      >
        <ListItemText>View my link profile</ListItemText>
        <OpenInNewIcon fontSize="small" />
      </MenuItem>
      <Box p="6px 16px">
        <Typography>My link:</Typography>
        <CopyUserProfileLink />
      </Box>
    </>
  );
};

export default ShareLinkDefaultMenu;
