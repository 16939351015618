import { Box, Grid, Typography, useTheme } from '@mui/material';

export default function LayoutItem({ layout, isSelected, setSelectedLayout }) {
  const muiTheme = useTheme();

  return (
    <Grid item xs={12}>
      <Box
        borderRadius={2}
        py={1}
        px={4}
        display="flex"
        alignItems="center"
        gap={2}
        sx={{
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            bgcolor: 'rgba(0,0,0,0.04)',
          },
        }}
        border={`3px solid ${isSelected ? muiTheme.colors.activeLink : 'transparent'}`}
        onClick={() => setSelectedLayout(layout)}>
        <img src={layout.image} alt="" height={64} />
        <Typography fontWeight="500">{layout.name}</Typography>
      </Box>
    </Grid>
  );
}
