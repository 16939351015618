import { Dialog, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CustomForm from './CustomForm';
import EmailForm from './EmailForm';

const EditLinkModal = ({ editingItem, onClose }) => {
  const render = () => {
    if (!editingItem) return null;
    if (editingItem.type === 'Email') return <EmailForm initData={editingItem} onClose={onClose} />;
    return <CustomForm initData={editingItem} type={editingItem.type} onClose={onClose} />;
  };

  return (
    <Dialog open={!!editingItem} onClose={onClose} maxWidth="xs" fullWidth disableEscapeKeyDown>
      <Box p={2} display="flex" flexDirection="column" gap={2} position="relative">
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        {render()}
      </Box>
    </Dialog>
  );
};

export default EditLinkModal;
