import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { storage } from '../configs/firebase.config';
import useProfile from './useProfile';

const useUploadFile = () => {
  const { user } = useProfile();

  const uploadFile = async (file, overrideStorageRef, folder) => {
    if (!user) return;
    const storageRef =
      overrideStorageRef ||
      `users/${user.id}/${folder || Date.now()}/${Date.now()}-${file.name}`;
    const fileRef = ref(storage, storageRef);
    await uploadBytes(fileRef, file);
    const url = await getDownloadURL(fileRef);

    return { storageRef, url };
  };

  return { uploadFile };
};

export default useUploadFile;
