import { Routes, Route } from 'react-router-dom';

import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';
import Login from '../pages/Login';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Success from '../pages/Register/Success';
import Verify from '../pages/Verify';
import Home from '../pages/Home';
import UserProfile from '../pages/UserProfile';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:resetPasswordRequestId" element={<ResetPassword />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/success" element={<Success />} />
      <Route path="/verify/:activateRequestId" element={<Verify />} />
      <Route path="/" element={<Home />} />
      <Route path="/:username/:handle" element={<UserProfile />} />
      <Route path="/:username" element={<UserProfile />} />
    </Routes>
  );
};

export default AuthRoutes;
