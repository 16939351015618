import { Box } from '@mui/material';

import Introduction from './components/Introduction';
import About from './components/About';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Footer from './components/Footer';

const Home = () => {
  return (
    <Box>
      <Introduction />
      <Features />
      <About />
      <Pricing />
      <Footer />
    </Box>
  );
};

export default Home;
