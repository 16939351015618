import { Drawer, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { navigations } from './Header';

const HeaderMenu = ({ isOpen, onClose }) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} sx={{ width: '100vw' }}>
      <Box
        width="100vw"
        height="100vh"
        overflow="auto"
        bgcolor="#0d0628"
        p={2}
        display="flex"
        flexDirection="column"
        gap={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CloseIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={onClose} />
          <Typography fontFamily="Himagsikan, sans-serif !important" color="white" fontSize={{ xs: 24, md: 32 }}>
            Connexions
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={5}>
          {navigations.map((navigation) => (
            <Box
              key={navigation.text}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(navigation.url);
                onClose();
              }}>
              <Typography textTransform="uppercase" color="white">
                {navigation.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default HeaderMenu;
