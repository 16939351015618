import { Helmet } from 'react-helmet';

export default function HeadTags({ title, description }) {
  const titleText = title ?? 'Unify your brands';
  const descriptionText =
    description ??
    'Say goodbye to cluttered bios and fragmented online identities. Join us in shaping the future of personal and professional branding.';

  return (
    <Helmet>
      <title key="title">{titleText} | Connexions</title>
      <meta name="description" content={descriptionText} key="desc" />
      <meta property="og:title" content={`${titleText} | Connexions`} />
      <meta property="og:description" content={descriptionText} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" type="image/png" href="/logo.png" />
    </Helmet>
  );
}
