import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useQueryClient } from '@tanstack/react-query';

import { auth } from '../configs/firebase.config';
import QueryKeys from '../utils/queryKeys';

const useAuth = () => {
  const queryClient = useQueryClient();
  const [isInitialized, setIsInitialized] = useState(false);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUid(firebaseUser?.uid || null);
      } else {
        setUid(null);
      }
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Links] });
      setIsInitialized(true);
    });

    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  return { isInitialized, uid };
};

export default useAuth;
