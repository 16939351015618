import { useState } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { signOut } from 'firebase/auth';

import ResetPasswordModal from './ResetPasswordModal';
import DeleteAccountModal from './DeleteAccountModal';
import useProfile from '../../../hooks/useProfile';
import { remove } from '../../../services/user.service';
import { auth } from '../../../configs/firebase.config';

const Security = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenResetPassword, setOpenResetPassword] = useState(false);
  const [isOpenDeleteAccount, setOpenDeleteAccount] = useState(false);
  const { user } = useProfile();
  const mutation = useMutation({
    mutationFn: remove,
    onSuccess: () => {
      signOut(auth);
      enqueueSnackbar('Remove account successfully', { variant: 'success' });
      setOpenDeleteAccount(false);
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onRemove = async () => {
    try {
      await mutation.mutateAsync();
    } catch (err) {
      console.error(err);
    }
  };

  if (!user) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ResetPasswordModal open={isOpenResetPassword} onClose={() => setOpenResetPassword(false)} />
      <DeleteAccountModal
        open={isOpenDeleteAccount}
        onClose={() => setOpenDeleteAccount(false)}
        onRemove={onRemove}
        disabled={mutation.isLoading}
      />
      <Typography fontSize="20px" fontWeight={500}>
        Security
      </Typography>
      <Box bgcolor="white" p={2} borderRadius={2} display="flex" flexDirection="column" gap={2}>
        <SecurityItem
          title="Password"
          RightComponent={() => (
            <Button size="small" variant="contained" color="neutral" onClick={() => setOpenResetPassword(true)}>
              Reset password
            </Button>
          )}
        />
        <Divider />
        <SecurityItem
          title="Disappear from the world?"
          RightComponent={() => (
            <Button size="small" variant="contained" color="error" onClick={() => setOpenDeleteAccount(true)}>
              Delete Account
            </Button>
          )}
        />
      </Box>
    </Box>
  );
};

const SecurityItem = ({ title, RightComponent }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
      <Typography>{title}</Typography>
      <RightComponent />
    </Box>
  );
};

export default Security;
