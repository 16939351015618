import { useEffect } from 'react';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import LayoutItem from './LayoutItem';
import useAppContext from '../../../hooks/useAppContext';
import { updateLayout } from '../../../services/profile.service';
import QueryKeys from '../../../utils/queryKeys';

export default function LayoutSelection({ selectedLayout, setSelectedLayout }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    profileState: { profile },
  } = useAppContext();

  useEffect(() => {
    const layout = layouts.find(({ id }) => id === (profile?.layoutId || 'dynamicWaves'));
    setSelectedLayout(layout);
  }, [profile?.layoutId, setSelectedLayout]);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data) => updateLayout(profile.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Profile] });
      enqueueSnackbar('Updated layout successfully', { variant: 'success' });
    },
    onError: (err) => {
      const error = (err.response && err.response.data) || err.message;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  const onSubmit = async () => {
    if (!selectedLayout) return;
    try {
      await onConfirmLayout();
    } catch (err) {
      console.error(err);
    }
  };

  const onConfirmLayout = async () => {
    try {
      await mutation.mutateAsync({ layoutId: selectedLayout.id });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontWeight="500" fontSize="20px">
        Layout Selection:
      </Typography>
      <Card
        sx={{
          p: 2,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
        <Grid container spacing={1}>
          {layouts.map((layout) => (
            <LayoutItem
              key={layout.id}
              layout={layout}
              isSelected={layout.id === selectedLayout?.id}
              setSelectedLayout={setSelectedLayout}
            />
          ))}
        </Grid>
      </Card>
      <Box>
        <Button
          variant="contained"
          color="neutral"
          onClick={onSubmit}
          disabled={profile?.layoutId === selectedLayout?.id}>
          Save Layout
        </Button>
      </Box>
    </Box>
  );
}

const layouts = [
  {
    id: 'dynamicWaves',
    name: 'Dynamic Waves',
    image: '/images/dynamic-waves.svg',
  },
  {
    id: 'solidBold',
    name: 'Solid Bold',
    image: '/images/solid-bold.svg',
  },
  // {
  //   id: 'translucentLayers',
  //   name: 'Translucent Layers',
  //   image: '/images/translucent-layers.svg',
  // },
];
