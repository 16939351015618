import { useState } from 'react';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useAppContext from '../../../hooks/useAppContext';
import environments from '../../../utils/environments';
import CopyUserProfileLink from './CopyUserProfileLink';

const { SITE_URL, BACKEND_URL } = environments;

const ShareLinkSocialsMenu = ({ handleClose, setCurrentPage }) => {
  const [open, setOpen] = useState(null);
  const {
    profileState: { user, profile },
  } = useAppContext();

  const userProfileLink = `${SITE_URL}/${user?.username}/${profile?.handle}`;

  return (
    <>
      <Typography fontWeight="bold" fontSize="18px" sx={{ padding: '0 16px 6px 6px' }}>
        <IconButton onClick={() => setCurrentPage('default')} sx={{ mr: 0.5 }}>
          <NavigateBeforeIcon />
        </IconButton>
        Share on Socials
      </Typography>
      <Divider />
      <MenuItem
        size="small"
        onClick={() => setOpen(open === 'instagram' ? null : 'instagram')}
        sx={{ width: 300 }}
        divider>
        <ListItemIcon>
          <img src={`${BACKEND_URL}/icons/instagram.webp`} alt="" width={24} />
        </ListItemIcon>
        <ListItemText>Instagram</ListItemText>
        {open === 'instagram' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </MenuItem>
      <Collapse in={open === 'instagram'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box p="6px 16px" sx={{ fontSize: 14 }}>
            <ListItemText sx={{ fontSize: 12 }}>Copy and put this link in your bio</ListItemText>
            <CopyUserProfileLink />
          </Box>
        </List>
      </Collapse>
      <MenuItem
        component="a"
        href={getIntentUrl({
          platform: 'twitter',
          url: userProfileLink,
          text: `View all ${profile?.name}'s Connexions now`,
        })}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleClose}
        divider>
        <ListItemIcon>
          <img src={`${BACKEND_URL}/icons/twitter.webp`} alt="" width={24} />
        </ListItemIcon>
        <ListItemText>Twitter</ListItemText>
        <OpenInNewIcon fontSize="small" />
      </MenuItem>
      <MenuItem size="small" onClick={() => setOpen(open === 'tiktok' ? null : 'tiktok')} sx={{ width: 300 }} divider>
        <ListItemIcon>
          <img src={`${BACKEND_URL}/icons/tiktok.webp`} alt="" width={24} />
        </ListItemIcon>
        <ListItemText>TikTok</ListItemText>
        {open === 'tiktok' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </MenuItem>
      <Collapse in={open === 'tiktok'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Box p="6px 16px" sx={{ fontSize: 14 }}>
            <ListItemText sx={{ fontSize: 12 }}>Copy and put this link in your bio</ListItemText>
            <CopyUserProfileLink />
          </Box>
        </List>
      </Collapse>
      <MenuItem
        component="a"
        href={getIntentUrl({ platform: 'linkedin', url: userProfileLink })}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleClose}>
        <ListItemIcon>
          <img src={`${BACKEND_URL}/icons/linkedin.webp`} alt="" width={24} />
        </ListItemIcon>
        <ListItemText>LinkedIn</ListItemText>
        <OpenInNewIcon fontSize="small" />
      </MenuItem>
    </>
  );
};

const intentUrlsBase = {
  twitter: 'https://twitter.com/intent/tweet',
  linkedin: 'https://www.linkedin.com/shareArticle/',
};

const getIntentUrl = ({ platform, url, text }) => {
  const intentUrl = new URL(intentUrlsBase[platform]);

  intentUrl.searchParams.append('url', url);
  text && intentUrl.searchParams.append('text', text);

  return intentUrl;
};

export default ShareLinkSocialsMenu;
